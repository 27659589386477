import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

const API_REQUESTS = {
  GET_HARDWARE_ERRORS: {
    path: '/errors/hardwareerror',
    method: HTTP_METHODS.GET,
  },
  GET_HARDWARE_ERRORS_JOB_BY_ID: {
    path: '/errors/hardwareerrorById',
    method: HTTP_METHODS.GET,
  },
};

export const ErrorApi = {
  fetchErrors: () => {
    return sendRequest(
      API_REQUESTS.GET_HARDWARE_ERRORS.method,
      `${API_REQUESTS.GET_HARDWARE_ERRORS.path}`
    );
  },

  fetchErrorById: (errorId) => {
    return sendRequest(
      API_REQUESTS.GET_HARDWARE_ERRORS_JOB_BY_ID.method,
      `${API_REQUESTS.GET_HARDWARE_ERRORS_JOB_BY_ID.path}/${errorId}` 
    );
  },
};