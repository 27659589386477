import { ClearFiltersButton } from "../../utils/shared/ClearFiltersButton";
import { DateRangeFilter } from "../../utils/shared/DateRangeFilter";
import { AppliedFilters } from "./AppliedFilters";

import { HardwareAndTechFilter } from "./HardwareAndTechFilter";
import { SearchBar } from "./SearchBar";


const getDropdownOptions = (hardwares, technicians) => ({
  hardwareDropdownOptions: hardwares?.map((hw) => ({
    name: hw.name,
    _id: hw._id,
  })),  
  technicianDropdownOptions: technicians?.map((tech) => ({
    username: tech.technicianId?.username,
    _id: tech.technicianId?._id,
  })),
});
const SearchFilters = ({ hardwares, technicians, handleSearchParams }) => {

  return (
    <>
      <div className="flex flex-wrap py-2 gap-3 items-center justify-end">
        <ClearFiltersButton />
        <SearchBar handleSearchParams={handleSearchParams} />
        <HardwareAndTechFilter
          {...getDropdownOptions(hardwares, technicians)}
        />
        <DateRangeFilter />
      </div>
      <AppliedFilters
        {...getDropdownOptions(hardwares, technicians)}
      />
    </>
  );
};

export default SearchFilters;
