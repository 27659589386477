import { useEffect, useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";

const StepDropdown = ({ index, flowStep, selectedDropdown, setSelectedDropdown, setErrorMessages }) => {
  const [open, setOpen] = useState(false);
  const [selectedName, setSelectedName] = useState("");

  useEffect(() => {
    if (flowStep === 3) {
      handleReInit();
    }
  }, [flowStep]);

  useEffect(() => {
   
    const selectedItem = selectedDropdown.find(
      (item) => item.isSelected === index
    )
    if (selectedItem) {
      setSelectedName(selectedItem.name);
    }
  }, [selectedDropdown, index]);
  
  const handleReInit = () => {
    selectedDropdown.forEach((item) => {
      if (item.isSelected === index) {
        setSelectedName(item.name);
      }
    });
  };
  


  const handleSelected = (field) => {
    const isDuplicate = selectedDropdown.some(
      (item, idx) =>
        (item.name === field.name && idx !== index && item.isSelected >= 0)
    );
  
    if (isDuplicate) {
      setErrorMessages((prevMessages) => [
        ...prevMessages,
        `Field "${field.name}" is already selected.`,
      ]);
      setOpen(false);
      return;
    }
  
    const temp_arr = selectedDropdown.map((single) => {
      if (single.name === field.name) {
        if (single.isSelected === index) {
          // If the same field is selected, deselect it
          single.isSelected = -1;
          setSelectedName(""); // Reset selectedName when deselecting
        } else {
          // Select the field
          single.isSelected = index;
          setSelectedName(single.name); // Update selectedName
        }
      } else if (single.isSelected === index) {
        // Deselect any previously selected field in the same dropdown
        single.isSelected = -1;
      }
      return single;
    });
  
    setSelectedDropdown(temp_arr);
    setErrorMessages([]); // Clear errors when a valid selection is made
    setOpen(false);
  };
    
  const IsSelected = () => {
    return selectedDropdown.some((item) => item.isSelected === index);
  };

const DropDown = () => {
  if (!selectedDropdown.length) return null;
  return (
    <>
    {selectedDropdown?.map((field, idx) => (
              <li key={field.key}>
                <button
                  className={`${
                    selectedDropdown[idx].isSelected >= 0 ? "bg-gray-200 cursor-not-allowed" : ""
                  } inline-flex w-full px-4 py-2 text-sm text-gray-700 hover:bg-gray-100`}
                  onClick={() => handleSelected(field)}
                  disabled={selectedDropdown[idx].isSelected >= 0 && selectedDropdown[idx].isSelected !== index}
                >
                  <div className="inline-flex items-center truncate">{field.name}</div>
                </button>
              </li>
            ))}
    </>
  )
}

  return (
    <div className="flex justify-around">
      <div className="w-4/5 relative">
        <button
          className="flex py-2 px-4 justify-between items-center w-full h-12 text-gray-800 border-2 border-gray-300 rounded-lg focus:outline-none focus:ring-0 focus:border-gray-300"
          onClick={() => setOpen(!open)}
        >
          <div className="truncate">
            {selectedName ? selectedName : <p className="text-gray-400">Map the Row</p>}
          </div>
          <ChevronDownIcon className="h-6 w-6 text-gray-500" />
        </button>
        <div className={`z-10 ${open ? "block" : "hidden"} absolute bg-white divide-y divide-gray-100 rounded-lg shadow w-full`}>
          <ul aria-labelledby="states-button" className="py-2 text-sm text-gray-700 max-h-40 overflow-auto">
            <DropDown/>
          </ul>
        </div>
      </div>
      <div className="w-1/5 flex justify-center items-center">
        <div className={`border-2 rounded-full ${!IsSelected() ? "border-gray-200 h-6 w-6" : "border-none"} text-blue-500 p-0`}>
          {IsSelected() && (
            <svg
              aria-hidden="true"
              className="w-8 h-8"
              fill="currentColor"
              viewBox="0 0 20 20"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                clipRule="evenodd"
                d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                fillRule="evenodd"
              ></path>
            </svg>
          )}
        </div>
      </div>
    </div>
  );
};

export default StepDropdown;
