import React from 'react'
import Step2 from "../../../components/clients/csvUploads/Step2"

const Step1 = () => {
  return (
      <Step2/>
  )
}

export default Step1
