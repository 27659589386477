import { addDays } from "date-fns";
import { enUS } from "date-fns/locale";
import { useCallback, useEffect, useState } from "react";
import { DateRangePicker } from "react-date-range";

import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { useSearchParams } from "react-router-dom";

const DateFilter = ({ setIsCalendarOpen }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [dateRange, setDateRange] = useState({
    startDate: searchParams.get("startDate")
      ? new Date(searchParams.get("startDate"))
      : new Date(),
    endDate: searchParams.get("endDate")
      ? new Date(searchParams.get("endDate"))
      : new Date(),
  });
  const [error, setError] = useState("");

  const selectionRange = {
    startDate: dateRange.startDate,
    endDate: dateRange.endDate,
    key: "selection",
  };

  const handleDateChange = useCallback((ranges) => {
    const { startDate, endDate } = ranges.selection;
    setDateRange({
      startDate: startDate,
      endDate: endDate,
    });
  }, []);

  const applyDateRange = () => {
    const { startDate, endDate } = dateRange;
    if (startDate > endDate) {
      setError("Start Date cannot be after End Date.");
      return;
    }

    setError("");
    setSearchParams((prevParams) => {
      const newParams = {
        ...Object.fromEntries(prevParams.entries()),
      };
      if (startDate) newParams.startDate = startDate;
      if (endDate) newParams.endDate = endDate;
      return newParams;
    });
    setIsCalendarOpen(false);
  };
  useEffect(() => {
    setDateRange({
      startDate: searchParams.get("startDate")
        ? new Date(searchParams.get("startDate"))
        : new Date(),
      endDate: searchParams.get("endDate")
        ? new Date(searchParams.get("endDate"))
        : new Date(),
    });
  }, [searchParams]);

  return (
    <div className="absolute z-50 w-[20rem] mt-2 bg-white shadow-lg rounded-md px-6 py-4 right-0">
      <div className="flex flex-col gap-3">
        <h1 className="font-medium text-md">Select Custom Date Range</h1>
        <p className="text-sm text-gray-700">
          All dates and time are presented in UTC. Usage data available for the
          last 18 months.
        </p>

        <DateRangePicker
          ranges={[selectionRange]}
          onChange={handleDateChange}
          editableDateInputs={true}
          showDateDisplay={false}
          months={1}
          direction="vertical"
          rangeColors={["#3d91ff"]}
          minDate={addDays(new Date(), -365 * 1.5)}
          maxDate={new Date(2033, 11, 31)}
          shownDate={new Date()}
          locale={enUS}
          moveRangeOnFirstSelection={false}
        />
        {error && <p className="text-red-500 text-sm">{error}</p>}

        <div className="w-full flex justify-between">
          <button
            className={`flex items-center justify-center bg-blue-700 text-center text-sm font-medium mt-4 py-2 px-3 rounded-lg mb-1 w-20 text-white cursor-pointer`}
            onClick={applyDateRange}
          >
            Apply
          </button>
        </div>
      </div>
    </div>
  );
};

export { DateFilter };
