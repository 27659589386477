import React, { useEffect, useState } from "react";
import { MapFilters } from "../../components/MapView/MapFilters";
import { ErrorAlert } from "../../components/Popups/ErrorAlert";
import { useApiOperation } from "../../hooks/useApiOperation";
import { HardwaresApi } from "../../lib/hardwares.api";
import Map from "../../utils/shared/map/map";

const AdminMapLayout = ({
  children,
  propertyId,
  setPropertyId,
  jobsData,
  setDetailsModal,
}) => {
  const { startApiOperation, terminateApiOperation, apiError } =
    useApiOperation();
  const [hardwares, setHardwares] = useState([]);

  useEffect(() => {
    fetchHardwares();
  }, []);

  if (!jobsData) return;

  const fetchHardwares = async () => {
    try {
      startApiOperation();
      const responseData = await HardwaresApi.fetchHardwares();
      setHardwares(responseData);
      terminateApiOperation();
    } catch (error) {
      console.error("Error fetching hardwares:", error);
      terminateApiOperation(["An error occurred while fetching hardwares"]);
    }
  };

  if (apiError) return <ErrorAlert errorMessages={apiError} />;

  return (
      <div className="w-full block p-5">
        <MapFilters hardwares={hardwares} />
        <div className="flex md:flex-row flex-col gap-x-4">
          <Map
            data={jobsData}
            propertyId={propertyId}
            setPropertyId={setPropertyId}
            setDetailsModal={setDetailsModal}
            isAdmin={true}
          />
          {children}
        </div>
      </div>
  );
};

export default AdminMapLayout;
