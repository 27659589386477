import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import DashboardLayout from "./components/DashboardLayout";
import Protected from "./components/Protected";
import AcceptInvitation from "./pages/AcceptInvitation";
import AdminMapView from "./pages/admin/page.jsx";
import AdminDashboard from "./pages/adminDashboard.jsx";
import AddClient from "./pages/client/add-client/page.jsx";
import ClientDetails from "./pages/client/client-details/page.jsx";
import CsvUploads from "./pages/client/csv-uploads/page.jsx";
import EditClientInfo from "./pages/client/edit-client/page.jsx";
import Step2 from "./pages/client/match-column/page.jsx";
import ClientsPage from "./pages/client/page.jsx";
import Step1 from "./pages/client/select-header/page.jsx";
import Step3 from "./pages/client/validate/page.jsx";
import Dashboard from "./pages/dashboard/dashboard";
import Errors from "./pages/Errors.jsx";
import JobDetails from "./pages/job-details/[id].jsx";
import Job from "./pages/job-in-progress/job.jsx";
import Jobs from "./pages/jobs/jobs.jsx";
import JobsOnProperty from "./pages/map-view/(filtered-pages)/jobs-on-property/page.jsx";
import MapView from "./pages/map-view/(filtered-pages)/properties/page.jsx";
import JobDetail from "./pages/map-view/job-detail/page.jsx";
import MyAccount from "./pages/myaccount";
import JobCompleted from "./pages/new-job-details/JobCompleted.jsx";
import NoPage from "./pages/nopage";
import OtaUpdates from "./pages/OtaUpdates.jsx";
import Profile from "./pages/Profile";
import Settings from "./pages/settings.jsx";
import Signin from "./pages/signin";
import SignUp from "./pages/signup";
import Start from "./pages/start-job/start.jsx";
import Tutorials from "./pages/Tutorials.jsx";
import Versions from "./pages/Versions.jsx";
import AcceptHardwareInvitation from "./pages/hardware/AcceptHardwareInvitation.jsx"

const App = () => {
  const retrieveUserFromLocalStorage = () => {
    try {
      const tokenObject = JSON.parse(localStorage.getItem("token"));
      if (!tokenObject || !tokenObject.accessToken) {
        return null;
      }
      const payload = JSON.parse(atob(tokenObject.accessToken.split(".")[1]));
      return payload?.role || null;
    } catch (error) {
      return null;
    }
  };

  const OnlyAdmin = ({ role, children }) => {
    return role === "admin" ? children : <Navigate to="/signin" />;
  };

const RouteElement = () => {
    const token = localStorage.token;
    const userRole = token ? retrieveUserFromLocalStorage() : null; 

    switch (userRole) {
        case 'admin':
            return <Navigate replace to="/admin/dashboard" />;
        case 'user': 
            return <Navigate replace to="/dashboard" />;
        default: 
            return <SignUp mode="signup" />;
    }
};


  return (
    <BrowserRouter>
      <Routes>
        {/* Redirect to appropriate dashboard based on role */}
        <Route
          path="/"
          element={
            localStorage.token ? (
              <Navigate replace to="/dashboard" />
            ) : (
              <Navigate replace to="/signin" />
            )
          }
        />
        <Route
          path="/signin"
          element={
            localStorage.token ? (
              retrieveUserFromLocalStorage() === "admin" ? (
                <Navigate replace to="/admin/dashboard" />
              ) : (
                <Navigate replace to="/dashboard" />
              )
            ) : (
              <Signin mode="signin" />
            )
          }
        />
        <Route path="/signup" element={<RouteElement />} />
        {/* Routes inside DashboardLayout */}
        <Route
          element={
            <Protected isLoggedIn={localStorage.token}>
              <DashboardLayout />
            </Protected>
          }
        >
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="mapview/properties" element={<MapView />} />
          <Route path="mapview/jobs-on-property/:id" element={<JobsOnProperty />} />
          <Route path="mapview/job-detail/:id" element={<JobDetail />} />
          <Route path="/jobs" element={<Jobs />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="myaccount" element={<MyAccount />} />
          <Route path="/start" element={<Start />} />
          <Route path="/profile" element={<Profile />} />
          {/* <Route path="/adminMap" element={<OnlyAdmin role={retrieveUserFromLocalStorage()}><AdminMapView /></OnlyAdmin >} /> */}
          <Route path="/clients" element={<ClientsPage />} />
          <Route path="/clients/add-new" element={<AddClient />} />
          <Route path="/clients/upload-csv" element={<CsvUploads />} />
          <Route path="/clients/select-header" element={<Step1 />} />
          <Route path="/clients/match-column" element={<Step2 />} />
          <Route path="/clients/validate" element={<Step3 />} />
          <Route path="/client/:id" element={<EditClientInfo />} />
          <Route path="/client-details/:id" element={<ClientDetails />} />
          <Route path="jobInProgress/:id" element={<Job />} />
          <Route path="/jobDetails/:id" element={<JobCompleted />} />
          <Route path="/job-details/:id" element={<JobDetails />} />
          <Route path="/tutorials" element={<Tutorials />} />
        </Route>

        {/* Admin-specific routes */}
        <Route
          path="/admin/*"
          element={
            <OnlyAdmin role={retrieveUserFromLocalStorage()}>
              <DashboardLayout />
            </OnlyAdmin>
          }
        >
          <Route path="dashboard" element={<AdminDashboard />} />
          <Route path="updates" element={<OtaUpdates />} />
          <Route path="versions" element={<Versions />} />
          <Route path="errors" element={<Errors />} />
          {/* <Route path="profile" element={<Profile />} />
        <Route path="adminMap" element={<AdminMapView />} />  */}
        </Route>

        <Route path="/adminMap" element={ <OnlyAdmin role={retrieveUserFromLocalStorage()}><DashboardLayout /></OnlyAdmin>
        }
        >
          <Route index element={<AdminMapView />} />
        </Route>
        <Route path="/invitation/accept-invitation/:email" element={<AcceptInvitation />} />
        <Route path="/hardware-invitation/accept-invitation/:hwid" element={<AcceptHardwareInvitation />} />
        <Route path="*" element={<NoPage />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
