import { useDispatch } from 'react-redux';
import { setNotifications } from '../../features/notificationsSlice';
import { useApiOperation } from '../../hooks/useApiOperation';
import { HardwaresApi } from '../../lib/hardwares.api';
import { NOTIFY_TYPE } from '../../utils/constants';
import { PopupBox } from '../Popups/PopupBox';
import { AddEditForm } from './addEditForm';

const EditHardware = ({ selectedHardware, setSelectedHardware, reFetch, openModal, setOpenModal }) => {
  const { name, description, hwid } = selectedHardware
  const dispatch = useDispatch()
  const { startApiOperation, terminateApiOperation } = useApiOperation()

  const validateInputs = () => {
    const errors = [];
    if (!name) errors.push('Hardware name is required');
    if (!description) errors.push('Hardware description is required');
    if (!hwid) errors.push('Hardware ID is required');

    return errors;
  };

  const handleSubmit = async () => {
    try {
      startApiOperation()
      const errors = validateInputs();
      if (errors.length) return terminateApiOperation([errors])
      await HardwaresApi.updateHardware(selectedHardware);
      await reFetch()
      terminateApiOperation(['Hardware Info Updated'], NOTIFY_TYPE.Success)
    } catch (error) {
      terminateApiOperation([
        'Something went wrong while updating hardware info. Please try again later',
      ]);
    } finally {
      setOpenModal(false)
    }
  }

  return (
    <>
      <PopupBox modal={openModal} setModal={setOpenModal} handleClose={() => setOpenModal(false)} title='Edit hardware' >
        <AddEditForm
          onSubmit={handleSubmit}
          hardware={selectedHardware}
          setHardware={setSelectedHardware}
          actionType="update"
        />
      </PopupBox>
    </>
  )
}
export default EditHardware
