import { GoogleMap, Marker, useJsApiLoader } from "@react-google-maps/api";
import React, { useCallback, useEffect, useState } from "react";
import markerPin from "./../../../assets/pin1.png";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";

const containerStyle = {
  height: "600px",
  borderRadius: "10px",
  boxShadow: "0px 0px 20px #8888",
};

const mapCenter = {
  lat: 42.06012414889974,
  lng: -91.63162586405998,
};

const Map = ({
  data,
  setPropertyId,
  propertyId,
  searchParams = {},
  setDetailsModal,
  isAdmin = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const [map, setMap] = useState(null);
  const [zoom, setZoom] = useState(8);
  const [selectedAddress, setSelectedAddress] = useState(null);
  const [center, setCenter] = useState(mapCenter);
  
  const onLoad = useCallback((map) => setMap(map), []);
  const onUnmount = useCallback(() => setMap(null), []);
  const { isLoaded } = useJsApiLoader({
    id: "google-map-script",
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
  });

  const mapWidth =
    window.innerWidth < 769 ? `${window.innerWidth - 40}px` : "100%";

  useEffect(() => {
    if (!propertyId) {
      setSelectedAddress(null);
      return;
    }
    let selected;
    if (data?.length) {
      // center=data[0].mapCenter || data[0],address?.mapCenter 
      setCenter(data[0].mapCenter || data[0].address?.mapCenter )
      selected = data?.find((property) => property?._id === propertyId);
    } else {
      selected = data.address;
    }
    if (selected) {
      setSelectedAddress(selected);
      setZoom(zoom + 20);
      map?.panTo(selected.mapCenter ?? selected.address.mapCenter);
      // setPropertyId(null);
    }
  }, [propertyId, data, map]);
  const onMarkerClick = (jobAddressId) => {
    if (propertyId === jobAddressId && setDetailsModal) {
      //this is for when user clicks on already selected marker
      setDetailsModal(true);
    }
    setZoom(zoom + 20);
    selectedAddress &&
      map?.panTo(
        selectedAddress?.mapCenter ?? selectedAddress?.address?.mapCenter
      );

    setPropertyId(jobAddressId);
  };

  const AdminMarkers = ({ data, selectedAddress, onMarkerClick, navigate, location, searchParams }) => {
    
    return (
      <>
        {data.map(
          (jobAddress) =>
            jobAddress._id !== selectedAddress._id && (
              <Marker
                key={jobAddress._id}
                position={
                  jobAddress.mapCenter ?? jobAddress.address.mapCenter
                }
                onClick={() => {
                  if (location.pathname === "/mapview/properties") {
                    navigate(
                      `/mapview/jobs-on-property/${jobAddress._id}?${searchParams}`,
                      { replace: true }
                    );
                  }
                  onMarkerClick(jobAddress._id);
                }}
                icon={{
                  url: markerPin,
                  scaledSize: new window.google.maps.Size(20, 20),
                  origin: new window.google.maps.Point(0, 0),
                }}
              />
            )
        )}
      </>
    );
  };

  const DisplayAddress = () => {
    if (selectedAddress) {
      return (
        <>
          <Marker
            key={selectedAddress?._id}
            position={
              selectedAddress?.mapCenter ?? selectedAddress?.address?.mapCenter
            }
            onClick={() => {
              onMarkerClick(selectedAddress?._id);
            }}
            icon={{
              url: markerPin,
              scaledSize: new window.google.maps.Size(30, 30),
              origin: new window.google.maps.Point(0, 0),
            }}
          />
          {Array.isArray(data) && isAdmin && (
            <AdminMarkers 
              data={data} 
              selectedAddress={selectedAddress} 
              onMarkerClick={onMarkerClick} 
              navigate={navigate} 
              location={location} 
              searchParams={searchParams} 
            />
          )}
        </>
      );
    } else {
      return (
        <>
          {Array.isArray(data) &&
            data.map((jobAddress) => (
              <Marker
                key={jobAddress._id}
                position={jobAddress.mapCenter ?? jobAddress.address.mapCenter}
                onClick={() => {
                  if (location.pathname === "/mapview/properties") {
                    navigate(
                      `/mapview/jobs-on-property/${jobAddress._id}?${searchParams}`,
                      { replace: true }
                    );
                  }
                  onMarkerClick(jobAddress._id);
                }}
                icon={{
                  url: markerPin,
                  scaledSize: new window.google.maps.Size(20, 20),
                  origin: new window.google.maps.Point(0, 0),
                }}
              />
            ))}
        </>
      );
    }
  };

  if (!isLoaded) return null;
  return (
    <GoogleMap
      mapContainerStyle={{ ...containerStyle, width: mapWidth }}
      center={data[0]?.mapCenter ?? center}
      onLoad={onLoad}
      onUnmount={onUnmount}
      zoom={zoom}
      options={{
        mapTypeId: "hybrid",
        streetViewControl: false,
        mapTypeControl: false,
        rotateControl: false,
        tilt: 0,
      }}
    >
      <DisplayAddress />
    </GoogleMap>
  );
};

export default React.memo(Map);