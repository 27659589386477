import Step1 from "../../../components/clients/csvUploads/Step1";


const CsvUploads = () => {
  

  return (
        <Step1/>
  );
};

export default CsvUploads;
