import { ChevronLeftIcon } from '@heroicons/react/24/outline';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from '../../../components/Button/PrimaryButton';
import { setSelectedRows, setStep2SelectedData } from "../../../features/csvDataSlice";
import { useApiOperation } from "../../../hooks/useApiOperation";
import Stepper from './Stepper';

const Step2 = () => {
  const dispatch = useDispatch();
  const csvData = useSelector((state) => state.csvData.csvData); 
  const [selectedRows, setSelectedRowsState] = useState([]);
  const [selectAll, setSelectAll] = useState(true);
  const currentStep = 2;

  const { terminateApiOperation } = useApiOperation();
  const navigate = useNavigate();

  useEffect(() => {
    if (!csvData.length) {
      navigate("/clients/upload-csv"); 
    } else {
      setSelectedRowsState(csvData.map((_, index) => index)); 
    }
  }, [csvData]);

  if (!csvData || csvData.length === 0) {
    return (
      <div className="p-5">
        <h2 className="text-2xl font-extrabold mb-6">Step 2: Review Data</h2>
        <p>No data available. Please go back and upload a file.</p>
      </div>
    );
  }

  const headers = Object.keys(csvData[0]);

  const handleRowSelection = (index) => {
    const newSelectedRows = selectedRows.includes(index)
      ? selectedRows.filter((rowIndex) => rowIndex !== index)
      : [...selectedRows, index];

    setSelectedRowsState(newSelectedRows); 
    dispatch(setSelectedRows(newSelectedRows)); 
  };

  const handleSelectAll = () => {
    if (selectAll) {
      const allRows = csvData.map((_, index) => index);
      setSelectedRowsState(allRows); // Update local state
      dispatch(setSelectedRows(allRows)); // Dispatch to Redux
    } else {
      setSelectedRowsState([]); // Deselect all
      dispatch(setSelectedRows([])); // Dispatch to Redux
    }
    setSelectAll(!selectAll);
  };

  const handleNext = () => {
    if (selectedRows.length === 0) {
      terminateApiOperation(["Please select at least one row before proceeding."]);
      return;
    }
    const selectedData = csvData.filter((_, index) => selectedRows.includes(index));
    dispatch(setStep2SelectedData(selectedData)); // Save selected rows to Redux
    navigate("/clients/match-column");
  };

  const handleBack = () => {
    navigate("/clients/upload-csv");
  };

  const Headers = () => {
    if(!headers.length) return null;  
    return <>{headers.map((header, index) => (
      <th
        key={index}
        className="px-4 py-2 text-left font-semibold text-gray-700"
      >
        {header}
      </th>
    ))}</>
  }

  const TableBody = () => {
    if (!csvData.length) return null;
    return <>{csvData.map((row, rowIndex) => (
      <tr
        key={rowIndex}
        className={rowIndex % 2 === 0 ? 'bg-white' : 'bg-gray-50'}
      >
        <td className="px-4 py-2 text-center">
          <input
            type="checkbox"
            checked={selectedRows.includes(rowIndex)}
            onChange={() => handleRowSelection(rowIndex)}
            className="w-5 h-5 text-green-600 bg-green-500 border-gray-300 focus:ring-2 rounded-md"
          />
        </td>
        {headers.map((header, colIndex) => (
          <td
            key={colIndex}
            className="px-4 py-2 text-gray-700 border-t border-gray-300"
          >
            {row[header]}
          </td>
        ))}
      </tr>
    ))}</>
  }

  return (
      <div className="p-5">
        <div className="flex justify-between">
          <PrimaryButton
            onClickEvent={handleBack}
            label={"Back"}
            icon={<ChevronLeftIcon className="w-4 h-4" />}
            classNames="gap-x-1 items-start"
          />
          <PrimaryButton
            onClickEvent={handleNext}
            label={'Next'}
            classNames="gap-x-1 items-start"
          />
        </div>
        <Stepper currentStep={currentStep} />

        <h2 className="text-2xl font-extrabold mb-6">Select Header Row</h2>
        <div className="border rounded-lg mb-6 overflow-auto" style={{ maxHeight: "550px" }}>
          <table className="table-auto w-full border-collapse border border-gray-200">
            <thead>
              <tr className="bg-gray-100 border-b border-gray-300">
                <th className="px-4 py-2 text-center">
                  <input
                    type="checkbox"
                    checked={selectAll}
                    onChange={handleSelectAll}
                    className="w-5 h-5 text-green-600 bg-green-500 border-gray-300 focus:ring-2 rounded-md"
                  />
                </th>
                <Headers/>
              </tr>
            </thead>
            <tbody>
              <TableBody/>
            </tbody>
          </table>
        </div>
      </div>
  );
};

export default Step2;
