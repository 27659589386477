import { CardCheckType, CheckboxVariants } from './../constants';
import { mergeClasses } from '../helpers';



const CardCheckbox = ({
  title,
  subTitle,
  value,
  setValue,
  checkboxStyles,
  cardStyles,
  type = CardCheckType.checkbox,
  onChange,
  variant = CheckboxVariants.PRIMARY,
  disabled,
  widgetThemeColor,
}) => {
  const CHECKBOX_VARIANTS_CONFIG = {
    [CheckboxVariants.PRIMARY]: 'w-6 h-6 rounded-lg text-green-600 bg-white border-gray-300 outline-none !ring-0 ',
    [CheckboxVariants.SINGLE]: 'w-5 h-5 mx-auto rounded-md cursor-pointer text-green-600 bg-white border-gray-300 focus:ring-transparent',
  };

  const checkBoxVariant = CHECKBOX_VARIANTS_CONFIG[variant];

  const checkboxMergedClasses = mergeClasses(checkBoxVariant, checkboxStyles);
  const cardClasses = mergeClasses(`relative flex items-start`, cardStyles);
  const inputType = CardCheckType[type];

  const handleOnChange = () => {
    if (setValue) setValue();
    if (onChange) onChange();
  };

  const Description = () => {
    if (!title && !subTitle) return null;
    return (
      <div className="ml-3 text-sm leading-6">
        <h1 className="text-wrap break-all">{title}</h1>
        <p className="text-secondary">{subTitle}</p>
      </div>
    );
  };

  return (
    <fieldset>
      <div className={cardClasses}>
        <div className="flex items-center hover:cursor-pointer">
          <input
            checked={value}
            onChange={handleOnChange}
            type={inputType}
            styles={checkboxMergedClasses}
            color={widgetThemeColor}
          />
        </div>
        <Description />
      </div>
    </fieldset>
  );
};




const Field = ({ label, text }) => {
  if (!text) return null;

  return (
    <div className="pt-3 text-sm sm:text-base">
      <h1 className="text-gray-500 font-medium mb-1">{label}</h1>
      <h1 className="break-words font-normal">{text} {label === "Area" ? "square feet" : ""} </h1>
    </div>
  );
};



export { CardCheckbox, Field };