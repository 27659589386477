import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

const API_REQUESTS = {
  GET_ALL_CLIENTS: {
    path: '/client/getAll',
    method: HTTP_METHODS.GET,
  },
  GET_CLIENT_BY_ID: {
    path: '/client/getClient',
    method: HTTP_METHODS.GET,
  },
  ADD_NEW_CLIENT: {
    path: '/client/create',
    method: HTTP_METHODS.POST,
  },
  ADD_NEW_CLIENT_In_Bulk: {
    path: '/client/bulk-upload-clients',
    method: HTTP_METHODS.POST,
  },
  BULK_UPLOAD_CLIENT: {
    path: '/client/bulk-upload',
    method: HTTP_METHODS.POST,
  },
  UPDATE_CLIENT_INFO: {
    path: '/client/updateClient',
    method: HTTP_METHODS.PATCH,
  },
  DELETE_CLIENT: {
    path: '/client/removeClient',
    method: HTTP_METHODS.DELETE,
  },
};



export const ClientsApi = {
  fetchClients: (data) => {
    return sendRequest(
      API_REQUESTS.GET_ALL_CLIENTS.method,
      `${API_REQUESTS.GET_ALL_CLIENTS.path}`,
      { params: data }
    );
  },
  fetchSingleClient: (id) => {
    return sendRequest(
      API_REQUESTS.GET_CLIENT_BY_ID.method,
      API_REQUESTS.GET_CLIENT_BY_ID.path,
      { params: id }
    );
  },
  addNewClient: (data) => {
    return sendRequest(
      API_REQUESTS.ADD_NEW_CLIENT.method,
      `${API_REQUESTS.ADD_NEW_CLIENT.path}`,
      data
    );
  },
  bulkUploadClient: (data) => {
    return sendRequest(
      API_REQUESTS.BULK_UPLOAD_CLIENT.method,
      `${API_REQUESTS.BULK_UPLOAD_CLIENT.path}`,
      data,
      'multipart/form-data',
    );
  },

  bulkUploadClientData: (clients) => {
    return sendRequest(
        API_REQUESTS.ADD_NEW_CLIENT_In_Bulk.method,
        `${API_REQUESTS.ADD_NEW_CLIENT_In_Bulk.path}`,
        { clients }, 
        'application/json'
    );
},

  updateClientInfo: (data) => {
    return sendRequest(
      API_REQUESTS.UPDATE_CLIENT_INFO.method,
      `${API_REQUESTS.UPDATE_CLIENT_INFO.path}`,
      data
    );
  },
  deleteClient: (id) => {
    return sendRequest(
      API_REQUESTS.DELETE_CLIENT.method,
      `${API_REQUESTS.DELETE_CLIENT.path}`,
      { id }
    );
  },
};