import { HTTP_METHODS } from "../utils/constants";
import { sendRequest2 } from "../utils/request-service";


const API_REQUESTS = {
  CREATE_USER: {
    path: '/user/signup',
    method: HTTP_METHODS.POST,
  },
  LOGIN_USER: {
    path: '/user/login',
    method: HTTP_METHODS.POST,
  },
  GET_USER: {
    path: '/user/getuser',
    method: HTTP_METHODS.GET,
  },
  ROTATE_TOKEN: {
    path: '/user/rotateToken',
    method: HTTP_METHODS.POST,
  },
  IS_VERIFIED_USER: {
    path: '/user/isVerified',
    method: HTTP_METHODS.GET,
  },
  GET_USER_TECHNICIANS: {
    path: '/user/getUserTechnicians',
    method: HTTP_METHODS.GET,
  },
  GET_OWNER_USER: {
    path: '/user/getOwnerUser',
    method: HTTP_METHODS.GET,
  },
  GET_TECHNICIAN_BY_USER: {
    path: '/user/getTechnician',
    method: HTTP_METHODS.GET,
  },
  CREATE_USER_TECHNICIANS: {
    path: '/user/createTechnician',
    method: HTTP_METHODS.POST,
  },
  VERIFY_EMAIL: {
    path: '/user/verify-email',
    method: HTTP_METHODS.GET,
  },
  DELETE_TECHNICIAN: {
    path: '/user/deleteTechnician',
    method: HTTP_METHODS.DELETE,
  },
};

export const AuthApi = {
  createUser: (credentials) => {    
    return sendRequest2(
      API_REQUESTS.CREATE_USER.method,
      API_REQUESTS.CREATE_USER.path,
      credentials
    );
  },
  loginUser: (credentials) => {
    return sendRequest2(
      API_REQUESTS.LOGIN_USER.method,
      API_REQUESTS.LOGIN_USER.path,
      credentials
    );
  },

  getUser: () => {
    return sendRequest2(
      API_REQUESTS.GET_USER.method,
      API_REQUESTS.GET_USER.path
    );
  },

  rotateToken: (refreshToken) => {
    return sendRequest2(
      API_REQUESTS.ROTATE_TOKEN.method,
      API_REQUESTS.ROTATE_TOKEN.path,
      { refreshToken }
    );
  },

  isVerifiedUser: (email) => {
    return sendRequest2(
      API_REQUESTS.IS_VERIFIED_USER.method,
      `${API_REQUESTS.IS_VERIFIED_USER.path}/${email}`
    );
  },

  getUserTechnicians: (queryParams = {}) => {
    return sendRequest2(
      API_REQUESTS.GET_USER_TECHNICIANS.method,
      API_REQUESTS.GET_USER_TECHNICIANS.path,
      null,
      queryParams
    );
  },

  getOwnerUser: (ownerUserId) => {
    return sendRequest2(
      API_REQUESTS.GET_OWNER_USER.method,
      `${API_REQUESTS.GET_OWNER_USER.path}/${ownerUserId}`
    );
  },

  getTechnicianByUser: (userId) => {
    return sendRequest2(
      API_REQUESTS.GET_TECHNICIAN_BY_USER.method,
      `${API_REQUESTS.GET_TECHNICIAN_BY_USER.path}/${userId}`
    );
  },

  createUserTechnicians: (
    email,
    role,
    username,
    password,
    passwordConfirm,
    status,
    ownerUserId
  ) => {
    return sendRequest2(
      API_REQUESTS.CREATE_USER_TECHNICIANS.method,
      API_REQUESTS.CREATE_USER_TECHNICIANS.path,
      {
        email,
        role,
        username,
        password,
        passwordConfirm,
        status,
        ownerUserId,
      }
    );
  },

  verifyEmail: (email) => {
    return sendRequest2(
      API_REQUESTS.VERIFY_EMAIL.method,
      `${API_REQUESTS.VERIFY_EMAIL.path}/${email}`
    );
  },

  deleteTechnician: (technicianId) => {
    if (!technicianId) {
      throw new Error("Technician ID is required for deletion.");
    }
    return sendRequest2(
      API_REQUESTS.DELETE_TECHNICIAN.method,
      `${API_REQUESTS.DELETE_TECHNICIAN.path}/${technicianId}`
    );
  },
  

  logoutUser: () => {
    localStorage.removeItem("token");
    window.location.reload();
  },
};
