 import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import AddHardware from '../../components/hardware/addHardware';
import { ListHardwares } from '../../components/hardware/renderCards';
import { Loader } from '../../components/Loader/Loader';
import { ErrorAlert } from '../../components/Popups/ErrorAlert';
import { useApiOperation } from '../../hooks/useApiOperation';
import { HardwaresApi } from '../../lib/hardwares.api';
import { PrimaryButton } from '../../components/Button/PrimaryButton';

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY);

const Hardware = () => {
    const location = useLocation();
    const { apiLoading, startApiOperation, terminateApiOperation, apiError } = useApiOperation();
    const [selectedHardware, setSelectedHardware] = useState({});
    const [hardwaresData, setHardwaresData] = useState([]);
    const [deleteModal, setDeleteModal] = useState(false);
    const [editModal, setEditModal] = useState(false);
    const [showAddHardware, setShowAddHardware] = useState(false);
    const [modalOpen, setModalOpen] = useState(false); // New state for modal trigger
    const [hwid, setHwId] = useState();

    useEffect(() => {
        fetchHardwares();

        // If redirected from activation, show the modal and trigger it
        if (location.state?.openModal) {
            setShowAddHardware(true);
            setModalOpen(true);
        }
        if(location.state?.hwid){
            const id = location.state?.hwid
            setHwId(id)
        }
        
    }, [location]);

    const fetchHardwares = async () => {
        try {
            startApiOperation();
            const responseData = await HardwaresApi.fetchHardwares();
            setHardwaresData(responseData);
            terminateApiOperation();
        } catch (error) {
            terminateApiOperation(['Error fetching data']);
        }
    };

    const deleteSelectedHardwares = async () => {
        try {
            startApiOperation();
            const { _id } = selectedHardware;
            await HardwaresApi.deleteHardware(_id);
            fetchHardwares();
            setDeleteModal(false);
            terminateApiOperation();
        } catch (error) {
            terminateApiOperation([error]);
        }
    };

    const DisplayLoader = () => apiLoading ? <Loader /> : null;
    const DisplayError = () => apiError ? <ErrorAlert errorMessages={apiError} /> : null;

    const handleRedirectToStripe = async () => {
        try {
            window.location.href = `https://buy.stripe.com/5kA17MeVJ3IE1wcbIJ`;
        } catch (error) {
            terminateApiOperation([error]);
        }
    };

    const ShowAddButton = () =>{
            if (!showAddHardware) return <div className='flex justify-end'>  
                <PrimaryButton  
                    label="Add hardware"  
                    onClickEvent={handleRedirectToStripe}  
                    displayPlus  
                />  
            </div>  
            return <AddHardware refetch={fetchHardwares} isOpen={modalOpen} setOpen={setModalOpen} HwId={hwid} />  
        }  

    return (
        <div>
            <DisplayLoader />
            <DisplayError />
            <Elements stripe={stripePromise}>
            <ShowAddButton/>
            </Elements>
            <ListHardwares
                deleteModal={deleteModal}
                setDeleteModal={setDeleteModal}
                editModal={editModal}
                setEditModal={setEditModal}
                hardwaresData={hardwaresData}
                deleteSelected={deleteSelectedHardwares}
                selectedHardware={selectedHardware}
                setSelectedHardware={setSelectedHardware}
                reFetch={fetchHardwares}
            />
        </div>
    );
};

export default Hardware;
