import React, { useEffect, useState } from 'react';
import { useApiOperation } from '../hooks/useApiOperation';
import { Table } from '../utils/shared/table/table';
import { ErrorApi } from '../lib/errors.api';
import dayjs from 'dayjs';
import { PopupBox } from "../components/Popups/PopupBox";

const getErrorsHeader = () => {
  return [
    { name: "HW_ID" },
    { name: "Error_logs_Time" },
    { name: "Date" },
  ];
};

const Errors = () => {
  const [errors, setErrors] = useState([]);
  const [selectedError, setSelectedError] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation();

  useEffect(() => {
    fetchErrorsHeader();
  }, []);

  const fetchErrorsHeader = async () => {
    try {
      startApiOperation();
      const data = await ErrorApi.fetchErrors();
      setErrors(data.results);
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation(['An error occurred while fetching latest jobs. Please try again later.']);
    }
  };

  const openModal = (error) => {
    setSelectedError(error);
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
    setSelectedError(null);
  };

  const getErrorData = () => {
    return errors.map((error) => ({
      hwid: (
        <div className="flex items-center">
          <button
            className="hover:text-blue-500 text-sm"
            onClick={() => openModal(error)}
          >
            {error.hwid}
          </button>
        </div>
      ),
      errorLogs: (
        <div className="flex flex-col">
          {error?.errorLogs && error?.errorLogs.length > 0 ? (
            <div className="flex items-center mb-1">
              <span className="text-sm mr-2">
                {dayjs(error.errorLogs[0].time).format("HH:mm:ss")}
              </span>
            </div>
          ) : (
            <p className="text-sm">No logs</p>
          )}
        </div>
      ),
      date: dayjs(error.createdAt).format("DD MMM YYYY"),
      actions: <></>,
    }));
  };

  const DisplayErrorLogs = () => {
    if (!selectedError.errorLogs || !selectedError.errorLogs.length) return <p>No logs available</p>
    return <ul className="list-disc ml-6">
      {selectedError.errorLogs.map((log, index) => (
        <li key={index}>
          <strong>Time:</strong> {dayjs(log.time).format('HH:mm:ss')}
          <div>
            <strong>Error:</strong>{' '}
            <span className="text-red-500">{log.erc}</span>
          </div>
        </li>
      ))}
    </ul>
  }

  const TableData = () => {
    if (!selectedError) return null;
    return <div>
      <p><strong>HW ID:</strong> {selectedError.hwid}</p>
      <p><strong>Date:</strong> {dayjs(selectedError.createdAt).format('DD MMM YYYY')}</p>
      <div>
        <strong>Error Logs:</strong>
        <DisplayErrorLogs />
      </div>
    </div>
  }

  const tableData = {
    headers: getErrorsHeader(),
    tabledata: getErrorData(),
  };

  return (
    <>
      <section className="mt-10 px-10">
        <div className="px-4 sm:flex-auto">
          <h1 className="text-3xl font-medium text-gray-900">Errors</h1>
          <p className="mt-2 text-sm text-gray-700">
            A list of all the Errors.
          </p>
          <Table {...tableData} />
        </div>
      </section>
      <PopupBox
        modal={isModalVisible}
        handleClose={closeModal}
        title="Error Details"
      >
        <TableData />
      </PopupBox>
      </>
  );
};

export default Errors;
