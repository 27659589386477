import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { AddClients } from '../../components/clients/add-clients'
import { ClientFilters } from '../../components/clients/client-filters'
import { ClientsList } from '../../components/clients/clients-list'
import { useApiOperation } from '../../hooks/useApiOperation'
import { ClientsApi } from '../../lib/clients.api'

const ClientsPage = () => {
  const [clients, setClients] = useState([])
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSearchParams = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set(key, value);
    setSearchParams(updatedParams);
  };

  const fetchClients = async () => {
    try {
      startApiOperation()
      const requestParams = {
        ...Object.fromEntries(searchParams.entries()),
        sortOrder: searchParams.get("sortOrder"),
        search: searchParams.get("search"),
      };
      const clients = await ClientsApi.fetchClients(requestParams)
      setClients(clients)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
    }
  }

  useEffect(() => {
    fetchClients()
  }, [searchParams])

  return (
      <div className='flex flex-wrap flex-col items-center divide-y'>
        <AddClients reFetch={fetchClients} />
        <ClientFilters />
        <ClientsList
          clients={clients}
          loading={apiLoading}
          handleSearchParams={handleSearchParams}
        />
      </div>
  )
}

export default ClientsPage