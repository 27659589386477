import { format } from "date-fns";
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import SearchFilters from "../../components/Search/SearchFilters";
import SearchTable from "../../components/Search/SearchTable";
import { useApiOperation } from "../../hooks/useApiOperation";
import { AuthApi } from '../../lib/auth.api'
import { HardwaresApi } from "../../lib/hardwares.api";
import { JobsApi } from "../../lib/jobs.api";


const params = {
  page: 0,
  search: "",
  role: "",
  hwid: [],
  technicianId: [],
  startDate: "",
  endDate: "",
};

const Jobs = () => {
  const { startApiOperation, terminateApiOperation, apiLoading } =
    useApiOperation();
  const [searchParams, setSearchParams] = useSearchParams(params);
  const [userData, setUserData] = useState([])
  const [hardwares, setHardwares] = useState([]);
  const [technicians, setTechnicians] = useState([]);
  const [jobs, setJobs] = useState([]);

  const fetchData = async () => {
    try {
      const data = await AuthApi.getUser();
      setUserData(data);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    fetchHardwares();
    fetchTechnicians();
  }, [userData]);

  useEffect(() => {
    if (userData) {
      fetchJobs();
    }
  }, [userData, searchParams]);

  const selectedHardwares = () => {
    const selected = searchParams.get("hwid")
      ? searchParams.get("hwid").split(",")
      : [];
    return selected;
  };

  const selectedTechnicians = () => {
    const selected = searchParams.get("technicianId")
      ? searchParams.get("technicianId").split(",")
      : [];
    return selected;
  };

  const fetchHardwares = async () => {
    try {
      startApiOperation();
      const responseData = await HardwaresApi.fetchHardwares();
      setHardwares(responseData);
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation([
        "An error occurred while fetching hardwares. Please try again later.",
      ]);
    }
  };

  const fetchTechnicians = async () => {
    if (!userData?.user?._id) return;
    try {
      startApiOperation();
      const { data } = await AuthApi.getUserTechnicians({ status: "Active" });
      setTechnicians(data);
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation([
        "An error occurred while fetching technicians. Please try again later",
      ]);
    }
  };

  const fetchJobs = async () => {
    try {
      startApiOperation();
      const requestParams = {
        ...Object.fromEntries(searchParams.entries()),
        hwid: selectedHardwares(),
        technicianId: selectedTechnicians(),
        role: userData?.user?.role,
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get("endDate") 
      };

      const data = await JobsApi.searchJobs(requestParams);
      setJobs(data);
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation([
        "An error occurred while fetching jobs. Please try again later.",
      ]);
    }
  };

  const handleSearchParams = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set(key, value);
    setSearchParams(updatedParams);
  };


  return (
    <section className="mt-10 px-10">
        <div className="px-4 sm:px-6 lg:px-8 w-full ">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto">
              <h1 className="text-3xl font-medium text-gray-900">Jobs</h1>
              <p className="mt-2 text-sm text-gray-700">
                A list of all the jobs in your account including their address,
                status, chemicals, and technician.
              </p>
            </div>
          </div>
          <SearchFilters
            hardwares={hardwares}
            technicians={technicians}
            handleSearchParams={handleSearchParams}
          />
        </div>

       <SearchTable
          data={jobs}
          isLoading={apiLoading}
          handleSearchParams={handleSearchParams}
        />
      </section>
  );
};

export default Jobs;
