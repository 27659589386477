import { HTTP_METHODS } from "../utils/constants";
import { sendRequest } from "../utils/request-service";

export const API_REQUESTS = {
  RETRIEVE_OR_CREATE_CUSTOMER: {
    path: '/payments/customer',
    method: HTTP_METHODS.POST,
  },
  CHARGE_CUSTOMER: {
    path: '/payments/chargeCustomer',
    method: HTTP_METHODS.POST,
  },
  GET_CUSTOMER_CARD: {
    path: '/payments/getCustomerCardInfo',
    method: HTTP_METHODS.GET,
  }
};

export const StripeApi = {
  getOrCreateCustomer: (data) => {
    return sendRequest(
      API_REQUESTS.RETRIEVE_OR_CREATE_CUSTOMER.method,
      `${API_REQUESTS.RETRIEVE_OR_CREATE_CUSTOMER.path}`,
      data
    );
  },
  createPaymentIntent: (data) => {
    return sendRequest(
      API_REQUESTS.CHARGE_CUSTOMER.method,
      `${API_REQUESTS.CHARGE_CUSTOMER.path}`,
      data
    );
  },
  getCustomerCardInfo: () => {
    return sendRequest(
      API_REQUESTS.GET_CUSTOMER_CARD.method,
      `${API_REQUESTS.GET_CUSTOMER_CARD.path}`
    );
  },

};