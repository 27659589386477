import { Menu, Transition } from "@headlessui/react";
import { CheckIcon, ChevronDownIcon } from "@heroicons/react/24/outline";
import { Fragment } from "react";

const FiltersDropDownMenu = ({
  title,
  searchParams,
  options,
  appliedOptions,
  setAppliedOptions,
  setSearchParams,
}) => {
  if (!appliedOptions.length) return null;

  const buttonStyles =
    title === "Hardware"
      ? "bg-blue-100 text-blue-800"
      : "bg-green-100 text-green-800";

  const toggleOption = (option) => {
    const updatedOptions = appliedOptions.includes(option)
      ? appliedOptions.filter((opt) => opt !== option)
      : [...appliedOptions, option];
    setAppliedOptions(updatedOptions);
    updateSearchParams(updatedOptions);
  };

  const updateSearchParams = (updatedOptions) => {
    const params = new URLSearchParams(searchParams);
    if (!updatedOptions.length) {
      if (title === "Hardware") {
        params.delete("hwid");
      } else if (title === "Technician") {
        params.delete("technicianId");
      }
    } else {
      const optionsParams = updatedOptions.map((option) => option._id);
      params.set(
        title === "Hardware" ? "hwid" : "technicianId",
        updatedOptions
      );
    }
    setSearchParams(params);
  };

  const DropdownOptions = () =>
    options.map((option) => (
      <Menu.Item key={option._id}>
        {({ active }) => (
          <button
            onClick={() => toggleOption(option._id)}
            className={`flex items-center w-full px-2 py-1 text-sm ${
              active ? "bg-gray-100" : ""
            }`}
          >
            <input
              type="checkbox"
              checked={appliedOptions.includes(option._id)}
              onChange={() => toggleOption(option._id)}
              className="mr-2 h-4 w-4 border-gray-300 rounded"
            />
            {option?.name || option?.username}
          </button>
        )}
      </Menu.Item>
    ));

  return (
    <Menu as="div" className="relative inline-block text-left">
      <div>
        <Menu.Button
          className={`inline-flex justify-center items-center px-4 py-2 ${buttonStyles} text-sm font-medium rounded-md hover:bg-gray-200 focus:outline-none`}
        >
          {title}
          <ChevronDownIcon className="w-5 h-5 ml-2" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="absolute mt-2 w-56 origin-top-left bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none z-50">
          <div className="px-4 py-2">
            <button
              onClick={() => {
                // setAppliedOptions(options);
                updateSearchParams(options.map((option) => option._id));
              }}
              className="flex items-center w-full px-2 py-1 text-sm text-blue-600 hover:bg-gray-100 rounded"
            >
              <CheckIcon className="w-4 h-4 mr-2" />
              Select All
            </button>
            <button
              onClick={() => {
                setAppliedOptions([]);
                updateSearchParams([]);
              }}
              className="flex items-center w-full px-2 py-1 mt-1 text-sm text-red-600 hover:bg-gray-100 rounded"
            >
              <CheckIcon className="w-4 h-4 mr-2" />
              Clear All
            </button>
          </div>
          <div className="py-1 px-2 min-h-0 max-h-40 overflow-auto">
            <DropdownOptions />
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  );
};

export { FiltersDropDownMenu };
