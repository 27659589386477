import React from 'react'
import { PrimaryButton } from '../Button/PrimaryButton'
import { useNavigate } from 'react-router-dom'
import { ArrowUpTrayIcon } from '@heroicons/react/24/outline'


const AddClients = ({ reFetch }) => {
  const navigate = useNavigate()
  return (
    <div className='flex justify-end w-full p-4 gap-3'>
       <PrimaryButton
        label='Upload Clients'
        icon={<ArrowUpTrayIcon className='w-5 h-5 mr-1' />}
        onClickEvent={() => navigate('/clients/upload-csv')}
      />
      <PrimaryButton
        label='Add New Client'
        onClickEvent={() => navigate('/clients/add-new')}
        displayPlus
      />
    </div>
  )
}

export { AddClients }