import {
  CardCvcElement,
  CardExpiryElement,
  CardNumberElement,
} from "@stripe/react-stripe-js";
import React, { useEffect, useMemo, useState } from "react";
import { useApiOperation } from "../../hooks/useApiOperation";
import { StripeApi } from "../../lib/stripe.api";
import { Input } from "../Input/Input";
import { Loader } from "../Loader/Loader";
import { DropdownMenuHC } from "../Menus/DropdownHC";
import ReactQuillComponent from "../ReactQuillComponent";
import CloseIcon from "../icons/CloseIcon";
import EditIcon from "../icons/EditIcon";
import InfoIcon from "../icons/InfoIcon"

const cardElementOptions = {
  style: {
    base: {
      color: "#000000",
      fontSize: "16px",
      "::placeholder": {
        color: "#B3B3B3",
      },
    },
    invalid: {
      color: "#FF0000",
    },
  },
};

const cardElementStyles =
  "w-full border-disabled-dark border rounded-md focus:border-primary focus:ring-0 text-sm p-2";

const FetchCardInfo = ({ actionType, setIsEditing, isEditing }) => {
  const { startApiOperation, terminateApiOperation } = useApiOperation();
  const [hasDefaultPaymentMethod, setHasDefaultPaymentMethod] = useState(false);
  const [placeHolders, setPlaceHolders] = useState({
    cardNumber: "1234 1234 1234 1234",
    expiry: "MM / YY",
    cvc: "CVC",
  });

  const fetchCustomerData = async () => {
    try {
      startApiOperation();
      const cardInfo = await StripeApi.getCustomerCardInfo();
      if (!cardInfo) {
        setIsEditing(true);
        return;
      }

      const { last4, exp_month, exp_year } = cardInfo;

      const placeHolderData = {
        cardNumber: `**** **** **** ${last4}`,
        expiry: `${exp_month} / ${exp_year}`,
        cvc: "****",
      };

      setPlaceHolders(placeHolderData);
      setHasDefaultPaymentMethod(true);
    } catch (error) {
      console.error("Error fetching customer data:", error);
      setIsEditing(true);
    } finally {
      terminateApiOperation();
    }
  };

  useEffect(() => {
    if (actionType === "add") {
      fetchCustomerData();
    }
  }, [actionType]);

  if (actionType !== "add") return null;

  return (
    <div className="mt-5">
      <div className="flex justify-between items-center">
        <p className="py-2 text-sm">Payment Details</p>
        <PaymentDetails
          hasDefaultPaymentMethod={hasDefaultPaymentMethod}
          isEditing={isEditing}
          setIsEditing={setIsEditing}
        />
      </div>

      <div className="w-full space-y-5 md:grid md:grid-cols-5 md:gap-2 md:space-y-0">
        <div className="col-span-3">
          <CardNumberElement
            className={cardElementStyles}
            id="cardNumber"
            options={{
              ...cardElementOptions,
              placeholder: placeHolders.cardNumber || "Card number",
              disabled: !isEditing,
            }}
          />
        </div>
        <div className="col-span-1">
          <CardCvcElement
            className={cardElementStyles}
            id="cvc"
            options={{
              ...cardElementOptions,
              placeholder: placeHolders.cvc || "CVC",
              disabled: !isEditing,
            }}
          />
        </div>
        <div className="col-span-1">
          <CardExpiryElement
            className={cardElementStyles}
            id="expiry"
            options={{
              ...cardElementOptions,
              placeholder: placeHolders.expiry || "Expiry date",
              disabled: !isEditing,
            }}
          />
        </div>
      </div>
    </div>
  );
};

const DisplayHardwareFields = ({ hardware, setHardware, HwId, actionType }) => {
  const { name, description, dropdown } = hardware;

  const hwid = HwId || hardware.hwid;
  const handleInputChange = (key, value) => {
    setHardware((prevHardware) => ({
      ...prevHardware,
      [key]: value,
    }));
  };

  const handleHwidChange = (actionType, handleInputChange, event) => {
    if (actionType !== "update" && actionType !== "add") {
        handleInputChange("hwid", event.target.value);
    }
  };

  return (
    <>
      <div className="mt-5">
        <Input
          options={{
            label: "name",
            name: "name",
            id: "name",
            value: name,
            onChange: (e) => handleInputChange("name", e.target.value),
            type: "text",
          }}
        />
      </div>

      <div className="mt-5">
        <p className="text-[14px]">description</p>
        <ReactQuillComponent
          content={description}
          setContent={(value) => handleInputChange("description", value)}
        />
      </div>

      <div className="mt-5 flex gap-x-3">
        <Input
          options={{
            label: "hwid",
            name: "hwid",
            id: "hwid",
            value: hwid, 
            onChange: (e) => handleHwidChange(actionType, handleInputChange, e),  
            type: "text",
            readOnly: true, 
          }}
        />
        <DropdownMenuHC
          label="Make Default?"
          dropdownOptions={[{ name: "Yes" }, { name: "No" }]}
          selector={dropdown}
          setSelector={(value) => handleInputChange("dropdown", value)}
          arrowDirectionUp={true}
        />
      </div>
    </>
  );
};

const PaymentDetails = ({
  hasDefaultPaymentMethod,
  isEditing,
  setIsEditing,
}) => {
  if (!hasDefaultPaymentMethod) return null;

  return (
    <div className="col-span-1">
      {isEditing ? (
        <div className="p-1 bg-gray-100 rounded-full">
          <CloseIcon onClick={() => setIsEditing(false)} />
        </div>
      ) : (
        <EditIcon onClick={() => setIsEditing(true)} />
      )}
    </div>
  );
};

const AddEditForm = ({
  onSubmit,
  hardware,
  setHardware,
  actionType = "update",
  loading = false,
  setIsEditing,
  isEditing,
  HwId
}) => {
  const memoizedHardwareFields = useMemo(
    () => (
      <DisplayHardwareFields hardware={hardware} setHardware={setHardware} HwId={HwId} actionType={actionType}/>
    ),
    [hardware, setHardware, HwId, actionType]
  );

  return (
    <>
      {memoizedHardwareFields}
      <FetchCardInfo
        actionType={actionType}
        setIsEditing={setIsEditing}
        isEditing={isEditing}
      />
     {actionType === "add" && (
        <div
          className="flex items-center bg-[#f3f4f6] text-blue-500 text-sm font-normal px-2 py-2 mt-5 rounded-md"
          role="alert"
        >
         <InfoIcon/>
          <p>
            Upon adding hardware, a one-time charge of $300 will be applied, and a
            monthly subscription fee of $59 will be required to continue using the
            hardware.
          </p>
        </div>
      )}      
      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          disabled={false}
          className="inline-flex justify-center max-h-10 w-full rounded-md border border-transparent px-4 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm"
          onClick={onSubmit}
        >
          {loading ? <Loader height="h-full" width="w-auto" /> : "Submit"}
        </button>
      </div>
    </>
  );
};

export { AddEditForm };
