import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Input } from '../Input/Input';
import CountryFilter from '../CountryFilter/CountryFilter';
import { UserIcon } from '@heroicons/react/24/outline';
import { AccountDetails } from "../../lib/updateAccounts.api";
import { useApiOperation } from "../../hooks/useApiOperation";

const EditProfile = () => {
    const [canUpdate, setCanUpdate] = useState(false);
    const [loading, setLoading] = useState(false);
    const [imageLoading, setImageLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState();
    const [imageFile, setImageFile] = useState(null);
    const [companyName, setCompanyName] = useState();
    const [companyPhone, setCompanyPhone] = useState("");
    const [companyAddress, setCompanyAddress] = useState("");
    const [companyWebsite, setCompanyWebsite] = useState("");
    const [shouldRefetch, setShouldRefetch] = useState(true);
    const [businessEmail, setBusinessEmail] = useState("");
    const [country, setCountry] = useState("United States");
    const [accountData, setAccountData] = useState({});
    const { startApiOperation, terminateApiOperation, apiLoading, apiError } = useApiOperation();
    
    const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;

    useEffect(() => {
        if (!shouldRefetch) return;
        fetchAccountDetails();
    }, [shouldRefetch]);

    useEffect(() => {
        if (accountData?.data?.length) {
            const firstAccount = accountData.data[0];
            setImageUrl(firstAccount.imageUrl || "");
            setCompanyName(firstAccount.companyName || "");
            setCountry(firstAccount.selectCountry || "United States");
            setCompanyPhone(firstAccount.phoneNumber || "");
            setCompanyAddress(firstAccount.address || "");
            setCompanyWebsite(firstAccount.website || "");
            setBusinessEmail(firstAccount.companyEmail || "");
        }
    }, [accountData]);

    const fetchAccountDetails = async () => {
        try {
            startApiOperation();
            const data = await AccountDetails.getAccountDetails();
            setAccountData(data);
            terminateApiOperation();
            setShouldRefetch(false);
        } catch (error) {
            terminateApiOperation(['An error occurred while fetching jobs on property. Please try again later.']);
            setShouldRefetch(false);
        }
    };

    const onDrop = useCallback((acceptedFiles) => {
        const file = acceptedFiles[0];
        if (file) {
            setImageFile(file);
            setImageUrl(URL.createObjectURL(file)); 
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
        disabled: !canUpdate,
    });

    const setCancel = () => {
        setCanUpdate(false);
        if (accountData?.data?.length) {
            const firstAccount = accountData.data[0];
            setImageUrl(firstAccount.imageUrl || "");
            setCompanyName(firstAccount.companyName || "");
            setCountry(firstAccount.selectCountry || "United States");
            setCompanyPhone(firstAccount.phoneNumber || "");
            setCompanyAddress(firstAccount.address || "");
            setCompanyWebsite(firstAccount.website || "");
            setBusinessEmail(firstAccount.companyEmail || "");
        }
        setImageFile(null); // Clear the image file
    };

    const onEditCustomer = () => {
        setCanUpdate(true);
    };

    const handleSave = async () => {
        try {
          setLoading(true);
      
          const formData = new FormData();
          formData.append("companyName", companyName);
          formData.append("phoneNumber", companyPhone);
          formData.append("address", companyAddress);
          formData.append("website", companyWebsite);
          formData.append("companyEmail", businessEmail);
          formData.append("selectCountry", country);
      
          if (imageFile) {
            formData.append("image", imageFile);
          }
          const response = await AccountDetails.updateAccountDetails(formData);

          if (response.data && response.data.imageUrl) {
            setImageUrl(`${IMAGE_URL}${response.data.imageUrl}`);
          }
      
          setLoading(false);
          setCanUpdate(false);
          setShouldRefetch(true);
        } catch (error) {
          setLoading(false);
          console.error("Failed to update profile:", error);
        }
      };
      const renderProfileImage = () => {
        if (imageLoading) return null; // If the image is still loading, return nothing
    
        if (imageUrl) {
            return (
                <img
                    src={imageUrl.startsWith("blob:") ? imageUrl : `${IMAGE_URL}${imageUrl}`}
                    alt="Profile"
                    className="w-[280px] lg:w-[450px] h-[250px] rounded-[5px] border-dotted p-4 bg-blue-50 border-[2px] border-[#548EFC] object-cover"
                />
            );
        }
    
        return (
            <div className="w-[150px] h-[150px] bg-[#548ffc18] rounded-full border-[3.5px] border-[#548EFC] flex items-center justify-center">
                <UserIcon className='w-16 h-16 text-[#548EFC]' />
            </div>
        );
    };
    return (
        <div>
            <div className='flex justify-between items-center'>
                <h1 className='text-base font-bold px-5'>Account</h1>
                <div className='flex justify-between items-center'>
                    {canUpdate && (
                        <button
                            className="text-[#1A1C1C] px-[34px] py-2 mr-4 rounded-lg border border-gray-300 text-sm font-medium leading-5 h-[36px]"
                            onClick={setCancel}
                        >
                            Cancel
                        </button>
                    )}
                    <button
                        className={`bg-[#548EFC] hover:bg-[#2563EB] text-white px-4 py-2 rounded-lg text-sm font-medium leading-5`}
                        onClick={canUpdate ? handleSave : onEditCustomer}
                    >
                        {loading ? 'Loading' : canUpdate ? 'Save changes' : 'Update Profile'}
                    </button>
                </div>
            </div>
            <hr className="my-5 bg-gray-100 text-gray-100" />
            <div className="md:flex md:justify-start md:items-start border border-gray-100 rounded-lg p-5 gap-[88px] w-full">
                <div className="mb-20 md:mb-0">
                    {imageLoading && <div>Loading...</div>}
                    {!imageLoading && renderProfileImage()}

                    <div>
                        <div {...getRootProps()} className="cursor-pointer mt-4">
                            {canUpdate ? (
                                <header className="w-28 mx-auto border border-gray-200 flex flex-col justify-center items-center py-2 px-4 rounded-lg hover:bg-gray-50 ">
                                    <input {...getInputProps()} />
                                    <p className=" text-black font-medium text-xs hover:text-[#548EFC]">
                                        Upload Image
                                    </p>
                                </header>
                            ) : null}
                        </div>
                    </div>
                </div>

                <div className="md:grid md:grid-cols-2 md:gap-5 space-y-5 md:space-y-0 w-full">
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Name',
                                name: 'companyName',
                                value: companyName,
                                disabled: !canUpdate,
                                onChange: (e) => setCompanyName(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Phone',
                                name: 'companyPhone',
                                type: "number",
                                value: companyPhone,
                                disabled: !canUpdate,
                                onChange: (e) => setCompanyPhone(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Address',
                                name: 'companyAddress',
                                type: "text",
                                value: companyAddress,
                                disabled: !canUpdate,
                                onChange: (e) => setCompanyAddress(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Company Website',
                                name: 'companyWebsite',
                                type: "text",
                                value: companyWebsite,
                                disabled: !canUpdate,
                                onChange: (e) => setCompanyWebsite(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <Input options={
                            {
                                label: 'Bussiness Email',
                                name: 'businessEmail',
                                type: "text",
                                value: businessEmail,
                                disabled: !canUpdate,
                                onChange: (e) => setBusinessEmail(e.target.value),
                            }
                        } />
                    </div>
                    <div className="col-span-1">
                        <CountryFilter
                            heading_label="Select Country"
                            id="tabs"
                            name="tabs"
                            disabled={!canUpdate}
                            canUpdate={canUpdate}
                            country={country}
                            defaultValue={"United States"}
                            countriesList={["United States", "Canada"]}
                            onChange={(e) => setCountry(e.target.value)}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default EditProfile;