import React from 'react'

const AdminDashboard = () => {
  return (
    <div className='flex items-center justify-center h-screen'>
      <h1 className='mx-auto text-3xl font-bold underline decoration-blue-300'>Admin Dashboard</h1>  
    </div>
  )
}

export default AdminDashboard
