import { useEffect, useState } from 'react';
import { AvailableVersions } from '../components/versions/available-versions';
import { UploadFile } from '../components/versions/upload-file';
import { useApiOperation } from '../hooks/useApiOperation';
import { VersionsApi } from '../lib/versions.api';

const VersionNameInput = ({ name, setName }) => {
  const handleNameChange = (event) => {
    event.preventDefault();
    setName(event.target.value);
  };

  return (
    <span className="w-1/3 items-end gap-2 flex flex-wrap pb-4">
      <label className="pt-2 text-md font-medium">Enter Version Name:</label>
      <div className="px-2 border-b border-gray-300 focus:border-sky-700">
        <input
          type="text"
          className="w-60 h-6 text-md bg-white border-none outline-none focus:ring-0 text-gray-800"
          placeholder="Enter text"
          value={name}
          onChange={(e) => handleNameChange(e)}
        />
      </div>
    </span>
  );
};

const Versions = () => {
  const [name, setName] = useState('');
  const [versions, setVersions] = useState([])
  const { startApiOperation, terminateApiOperation, apiLoading } = useApiOperation()

  const fetchVersions = async () => {
    try {
      startApiOperation()
      const versions = await VersionsApi.getVersions()
      setVersions(versions)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
    }
  }

  useEffect(() => {
    fetchVersions()
  }, [])

  return (
      <div className="w-full flex flex-wrap justify-start items-center p-5 flex-col h-screen divide-y">
        <h1 className='text-3xl font-medium text-gray-800 pb-3'>Version Updates and Monitoring</h1>
        <div className="flex w-full h-20 justify-start items-center">
          <VersionNameInput name={name} setName={setName} />
        </div>
        <div className="w-full gap-5 py-4 flex h-4/5">
          <UploadFile name={name} setName={setName} fetchVersions={fetchVersions} />
          {Array.isArray(versions) && versions.length > 0 ? (
            <AvailableVersions versions={versions} loading={apiLoading} />
          ) : (
            <div className="w-full text-center text-gray-500">No data available</div>
          )}
        </div>
      </div>
  );
};

export default Versions;
