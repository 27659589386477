import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button/PrimaryButton'
import { Input } from '../../../components/Input/Input'
import { AddEditForm } from '../../../components/clients/add-edit-form'
import { useApiOperation } from '../../../hooks/useApiOperation'
import { ClientsApi } from '../../../lib/clients.api'
import { NOTIFY_TYPE } from '../../../utils/constants'

const initialState = {
  clientName: '',
  email: '',
  property: {
    address: '',
    mapCenter: {
      lat: 0,
      lng: 0
    },
    squareFeet: ''
  }
}

const DisplaySqFeet = ({ squareFeet, handleChange }) => {
  return <Input key='sq-feet-key' options={
    {
      label: 'Square Feet',
      name: 'sqft',
      type: "text",
      value: squareFeet,
      onChange: (e) => handleChange('property.squareFeet', e.target.value)
    }} />
}

const EditClientInfo = () => {
  const { id } = useParams()
  const navigate = useNavigate()
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [clientDetails, setClientDetails] = useState(initialState)

  const handleClientDetailsChange = (name, value) => {
    setClientDetails((prevDetails) => {
      if (name === 'property.squareFeet') {
        return {
          ...prevDetails,
          property: {
            ...prevDetails.property,
            squareFeet: value,
          },
        };
      }
      return {
        ...prevDetails,
        [name]: value,
      };
    });
  };

  const fetchClient = async () => {
    try {
      startApiOperation()
      const client = await ClientsApi.fetchSingleClient({ clientId: id })
      setClientDetails(client)
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
    }
  }


  const handleSaveClient = async () => {
    try {
      startApiOperation()
      const clientPayload = {
        clientId: id,
        clientName: clientDetails.clientName,
        email: clientDetails.email,
        squareFeet: clientDetails.property.squareFeet,
        propertyId: clientDetails.property._id
      }
      await ClientsApi.updateClientInfo(clientPayload)
      terminateApiOperation(['Client Info Updated Successfully'], NOTIFY_TYPE.Success)
      navigate(-1)
    } catch (error) {
      terminateApiOperation([error])
    }
  }


  const AddressBar = () => {
    return (
      <div className='flex py-2 w-full gap-4 items-start'>
        <Input options={
          {
            label: 'Address',
            name: 'address',
            type: "text",
            value: clientDetails.property.address,
            disabled: true
          }} />
      </div>
    )
  }

  useEffect(() => {
    fetchClient()
  }, [id])

  return (
      <div className='flex flex-wrap flex-col p-10 w-full xl:w-1/2 gap-2'>
        <p className='flex sm:hidden font-medium text-2xl text-gray-500'>Edit Client Info</p>
        <div className="w-full flex justify-between items-end ">
          <div>
            <PrimaryButton
              onClickEvent={() => navigate(-1)}
              label={'Back'}
              icon={<ChevronLeftIcon className='w-4 h-4' />}
              classNames='gap-x-1 items-start ' />
          </div>
          <p className='hidden sm:flex font-medium text-2xl text-gray-500'>Edit Client Info</p>
          <button
            type="button"
            disabled={false}
            className=" rounded-md border border-transparent px-8 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleSaveClient}
          >
            Save
          </button>
        </div>
        <AddEditForm clientDetails={clientDetails} handleChange={handleClientDetailsChange} />
        <AddressBar />
        <DisplaySqFeet handleChange={handleClientDetailsChange} squareFeet={clientDetails.property.squareFeet} />
      </div>
  )
}

export default EditClientInfo
