import { DashboardActions } from "../../components/dashboard/dashboard-actions";
import { DashboardTable } from "../../components/dashboard/dashboard-table";

export default function Dashboard() {
  return (
      <section className="m-5 gap-5 flex flex-col divide-y">
        <DashboardActions />
        <DashboardTable />
      </section>
  );
}
