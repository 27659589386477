import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { ClearFiltersButton } from "../../utils/shared/ClearFiltersButton";
import { DateRangeFilter } from "../../utils/shared/DateRangeFilter";
import { SearchableDropdown } from "../../utils/shared/searchable-dropdown/SearchableDropdown";

const MapFilters = ({ hardwares }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const getHardwareName = () => {
    const selected = searchParams.get("hardwareId") || "All Hardwares";
    const hardware = hardwares.find((h) => h.hwid === selected);
    return hardware ? hardware.name : "All Hardwares";
  };
  const [selectedOption, setSelectedOption] = useState(getHardwareName());

  useEffect(() => {
    const newSearchParams = new URLSearchParams(searchParams);
    if (selectedOption === "All Hardwares") {
      newSearchParams.delete("hardwareId");
    } else {
      const hardwareId = hardwares.find((h) => h.name === selectedOption)?.hwid;
      if (hardwareId) {
        newSearchParams.set("hardwareId", hardwareId);
      }
    }
    setSearchParams(newSearchParams);
  }, [selectedOption]);

  useEffect(() => {
    if (!searchParams.get("hardwareId")) {
      setSelectedOption("All Hardwares");
    }
  }, [searchParams]);

  return (
    <div className="flex flex-wrap items-center justify-end mb-3 gap-2 px-0 w-">
      <div className="flex mx-3 items-center justify-center">
        <ClearFiltersButton />
      </div>
      <div className="flex h-full">
        <SearchableDropdown
          options={[
            { name: "All Hardwares" },
            ...hardwares?.map((hardware) => ({
              name: hardware.name,
              hwid: hardware.hwid,
              id: hardware._id,
            })),
          ]}
          selectedOption={selectedOption}
          setSelectedOption={setSelectedOption}
          placeholder="Search Hardwares"
        />
      </div>
      <div className="flex mw-full">
        <DateRangeFilter />
      </div>
    </div>
  );
};

export { MapFilters };
