import { LockClosedIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/solid';
import joi from 'joi';
import { useEffect, useState } from 'react';
import { ErrorAlert } from '../components/Popups/ErrorAlert';
import { AuthApi } from '../lib/auth.api';
import { Link } from 'react-router-dom';
import { useApiOperation } from "../hooks/useApiOperation";
import { NOTIFY_TYPE } from "../utils/constants";

const SignUp = () => {
    const [username, setName] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [passwordConfirm, setConfirmPassword] = useState('');
    const [validationError, setValidationError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const { startApiOperation, terminateApiOperation } = useApiOperation();

    const valid = () => {
        const schema = joi.object({
            username: joi.string().min(3).required().messages({
                "string.empty": "Name is required",
                "string.min": "Name must be at least 3 characters"
            }),
            email: joi.string().email({ tlds: { allow: false } }).required().messages({
                "string.email": "Invalid email format",
                "string.empty": "Email is required"
            }),
            password: joi.string().min(6).required().messages({
                "string.empty": "Password is required",
                "string.min": "Password must be at least 6 characters"
            }),
            passwordConfirm: joi.any().valid(joi.ref('password')).required().messages({
                "any.only": "Passwords do not match",
                "string.empty": "Confirm Password is required"
            })
        });

        const result = schema.validate({ username, email, password, passwordConfirm }, { abortEarly: false });

        if (!result.error) {
            return true;
        } else {
            setValidationError(result.error.details.map(err => err.message).join(", "));
            return false;
        }
    };

    const handleSubmit = async (e) => {
        startApiOperation();
        e.preventDefault();
        try {
            if (!valid()) return;
            const userData = { username, email, password, passwordConfirm };
            const response = await AuthApi.createUser(userData);
            if (response) {
                console.log("Account created successfully");
                terminateApiOperation(
                    ["Your account is created successfully but inActive please verify your account through email"],
                    NOTIFY_TYPE.Success
                );
                window.location.href = "/signin";
            }
        } catch (error) {
            setValidationError(error.message || "An unexpected error occurred. Please try again.");
        }
    };

    useEffect(() => {
        setValidationError('');
    }, [username, email, password, passwordConfirm]);

    const ShowPassword = () => {
        if (!showPassword) return (
            <EyeIcon className="w-5 h-5 text-gray-600" />);
        return (
            <EyeSlashIcon className="w-5 h-5 text-gray-600" />
        )
    }
    const ConfirmPassword = () => {
        if (!showConfirmPassword) return (
            <EyeIcon className="w-5 h-5 text-gray-600" />
        )
        return (
            <EyeSlashIcon className="w-5 h-5 text-gray-600" />
        )
    }

    return (
        <section className='h-screen bg-gray-50 flex items-center justify-center'>
            <div className='p-6 max-w-md w-full bg-white shadow-md rounded-lg'>
                <img className="mx-auto h-12" src='/smartSpray-logo.png' alt="Smart Spray Logo" />
                <h2 className='text-center text-3xl font-bold text-gray-900 py-5'>Create Your Account</h2>
                <div className='text-blue-500 py-3 hover:text-blue-400 hover:underline flex justify-end'>
                    <Link to={"/signin"}>Sign In</Link>
                </div>

                <form className='space-y-6' onSubmit={handleSubmit}>
                    <div className='rounded-md flex flex-col gap-3'>

                        <input
                            className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-t-md focus:outline-none focus:ring-indigo-500 focus:ring-1 focus:border-indigo-500 focus:z-10 sm:text-sm'
                            type="text"
                            autoComplete='name'
                            placeholder='Full Name'
                            onChange={(e) => setName(e.target.value)}
                            value={username}
                        />

                        <input
                            className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900  focus:outline-none focus:ring-indigo-500 focus:ring-1 focus:border-indigo-500 focus:z-10 sm:text-sm'
                            type="email"
                            autoComplete='email'
                            placeholder='Email Address'
                            onChange={(e) => setEmail(e.target.value)}
                            value={email}
                        />

                        <div className="relative">
                            <input
                                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:ring-1 focus:border-indigo-500 focus:z-10 sm:text-sm'
                                type={showPassword ? "text" : "password"}
                                autoComplete='new-password'
                                placeholder='Password'
                                onChange={(e) => setPassword(e.target.value)}
                                value={password}
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-3 flex items-center"
                                onClick={() => setShowPassword(!showPassword)}
                            >
                                <ShowPassword />
                            </button>
                        </div>

                        <div className="relative">
                            <input
                                className='appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500 focus:ring-1 focus:border-indigo-500 focus:z-10 sm:text-sm'
                                type={showConfirmPassword ? "text" : "password"}
                                autoComplete='new-password'
                                placeholder='Confirm Password'
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                value={passwordConfirm}
                            />
                            <button
                                type="button"
                                className="absolute inset-y-0 right-3 flex items-center"
                                onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                            >
                                <ConfirmPassword />
                            </button>
                        </div>

                    </div>

                    <button
                        type="submit"
                        className="group relative w-full flex justify-center py-2 px-4 border border-transparent text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                        <span className="absolute left-0 inset-y-0 flex items-center pl-3">
                            <LockClosedIcon className='text-white w-4' />
                        </span>
                        Sign Up
                    </button>
                </form>

                {validationError && <div className='mt-5'><ErrorAlert errorMessages={validationError} /></div>}
            </div>
        </section>
    );
};

export default SignUp;
