import React, { useState } from 'react';
import { Input } from '../Input/Input';
import { DropdownMenuHC } from '../Menus/DropdownHC';
import ReactQuillComponent from '../ReactQuillComponent';
import { useApiOperation } from '../../hooks/useApiOperation'

const dropdownOptions = [
  { name: 'Ounces per gallon' },
  { name: 'Grams per gallon' },
  { name: 'Mililiter per gallon' },
];

const AddEditForm = ({ onSubmit, chemical, setChemical }) => {
  const { chemicalName, description, concentration, measurement } = chemical;
  const [error, setError] = useState('');
  const { startApiOperation, terminateApiOperation } = useApiOperation();

  const handleInputChange = (key, value) => {
    setChemical((prevChemical) => ({
      ...prevChemical,
      [key]: value,
    }));
    

    if (key === 'concentration') {
      startApiOperation();
      if (!value || isNaN(value) || Number(value) <= 0) {
        setError('Measurement must be a positive number');
      } else {
        setError('');
      }
    }
  };
  

  const handleSubmit = () => {
    if (isFormValid()) {
      onSubmit();
      setChemical({
        chemicalName: '',
        description: '',
        concentration: '',
        measurement: '',
      });
    }
  };

  const isDescriptionValid = (desc) => {
    const trimmedDesc = desc.trim();
    return trimmedDesc !== '' && trimmedDesc !== '<p><br></p>'; // Explicitly check for empty Quill content
  };

  const isFormValid = () => {
    return (
      chemicalName.trim() &&
      isDescriptionValid(description) && // Check for valid description
      concentration &&
      !isNaN(concentration) &&
      Number(concentration) > 0 &&
      measurement.trim()
    );
  };

  return (
    <>
      <div className="mt-5">
        <Input
          options={{
            label: 'name',
            name: 'name',
            id: 'name',
            value: chemicalName,
            onChange: (e) => handleInputChange('chemicalName', e.target.value),
            type: 'text',
          }}
        />
      </div>

      <div className="mt-5">
        <p className="text-[14px]">description</p>
        
        <ReactQuillComponent content={description} setContent={(value) => handleInputChange('description', value)} />
      </div>

      <div className="mt-5 flex gap-x-3">
        <div className="w-full">
          <Input
            options={{
              label: 'measurement',
              name: 'concentration',
              id: 'concentration',
              value: concentration,
              onChange: (e) => handleInputChange('concentration', e.target.value),
              type: 'number',
            }}
          />
          {error && <p className="text-red-500 text-xs mt-1">{error}</p>}
        </div>
        <DropdownMenuHC
          label="unit"
          dropdownOptions={dropdownOptions}
          selector={measurement}
          setSelector={(value) => handleInputChange('measurement', value)}
          arrowDirectionUp={true}
        />
      </div>

      <div className="mt-5 sm:mt-6">
        <button
          type="button"
          disabled={!isFormValid()}
          className={`inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 ${
            isFormValid() ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-300 cursor-not-allowed'
          } text-sm font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:text-sm`}
          onClick={handleSubmit}
        >
          Submit
        </button>
      </div>
    </>
  );
};

export { AddEditForm };

