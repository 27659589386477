import React from 'react'
import Step3 from "../../../components/clients/csvUploads/Step3"

const Step2 = () => {
  return (
      <Step3/>
  )
}

export default Step2
