import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import notificationsReducer from "../features/notificationsSlice";
import jobDetailsReducer from "../features/jobDetailsSlice";
import jobInProgressReducer from "../features/jobInProgressSlice";
import modalReducer from "../features/modalSlice";
import csvDataReducer from '../features/csvDataSlice';


const rootReducer = combineReducers({
  jobDetails: jobDetailsReducer,
  jobInProgress: jobInProgressReducer,
  modal: modalReducer,
  notifications: notificationsReducer,
  csvData: csvDataReducer,
});

const persistConfig = {
  key: "root",
  storage,
  whitelist: ["jobDetails", "jobInProgress"],
};


const persistedReducer = persistReducer(persistConfig, rootReducer);


export const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export const persistor = persistStore(store);
