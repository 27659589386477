import { ArrowUpTrayIcon, ChevronLeftIcon } from '@heroicons/react/24/outline';
import Papa from 'papaparse';
import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { PrimaryButton } from '../../../components/Button/PrimaryButton';
import { setCsvData } from "../../../features/csvDataSlice";
import { useApiOperation } from "../../../hooks/useApiOperation";
import { NOTIFY_TYPE } from "../../../utils/constants";
import ExampleTable from './ExampleTable';
import Stepper from './Stepper';


const Step1 = () => {
  const dispatch = useDispatch();
  const [fileInfo, setFileInfo] = useState(null); 
  const navigate = useNavigate();
  const currentStep = 1;
  const { startApiOperation, terminateApiOperation } = useApiOperation();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];

    if (!file) {
      terminateApiOperation(["CSV file is required."]);
      return;
    }

    if (file.type !== 'text/csv') {
      terminateApiOperation(["Please upload a valid CSV file."]);
      return;
    }

    setFileInfo(file);

    const reader = new FileReader();
    reader.onload = () => {
      Papa.parse(reader.result, {
        header: true,
        skipEmptyLines: true,
        complete: (result) => {
          if (!result.data || result.data.length === 0) {
            terminateApiOperation(["The CSV file is empty."]);
            return;
          }
          dispatch(setCsvData(result.data));
          terminateApiOperation(["CSV file uploaded successfully!"], NOTIFY_TYPE.Success);
        },
        error: () => {
          terminateApiOperation(["Error parsing the CSV file."]);
        },
      });
    };
    reader.readAsText(file);
  }, [dispatch, terminateApiOperation]);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: '.csv',
  });

  const handleNext = () => {
    startApiOperation();
    if (!fileInfo) {
      terminateApiOperation(["CSV file is required."]);
      return;
    }
    navigate('/clients/select-header');
  };

  const DisplaySelectedFile = () => {  
    if (!fileInfo) return null  
    return  <p className="text-center text-lg font-semibold mt-4 tracking-wide">Selected file: {fileInfo.name}</p>  
  } 

  return (
    <div className="p-5">
      <div className="flex justify-between">
        <PrimaryButton
          onClickEvent={() => navigate('/clients')}
          label={'Back'}
          icon={<ChevronLeftIcon className="w-4 h-4" />}
          classNames="gap-x-1 items-start"
        />
        <PrimaryButton
          onClickEvent={handleNext}
          label={'Next'}
          classNames="gap-x-1 items-start"
        />
      </div>

      <Stepper currentStep={currentStep} />

      <div className="py-4">
        <div>
          <h2 className="text-2xl font-extrabold mb-6">Upload File</h2>
          <p className="text-lg font-semibold">Data that we expect:</p>
          <p className="text-md font-light mb-4 text-gray-700">
            You will have a chance to rename or remove columns in the next steps.
          </p>
        </div>
        <div className="my-4 border-r-1 border-l-1 border-t-1 rounded-t-lg">
          <ExampleTable />
        </div>
        <div
          {...getRootProps()}
          className="flex items-center justify-center w-full bg-white shadow-md border-3 border-gray-300 border-dashed px-20 py-14 rounded-md hover:bg-gray-200 hover:border-gray-500 cursor-pointer mt-1"
          style={{ minHeight: '400px' }}
        >
          <input {...getInputProps()} accept=".csv" type="file" />
          <div>
            <ArrowUpTrayIcon className="block mx-auto w-full max-w-[75px]" />
            <p className="text-center text-lg font-semibold mt-4 tracking-wide">Upload CSV file</p>
            <p className="text-center text-md mt-1 tracking-wide">Format: .csv</p>
            <DisplaySelectedFile/>
          </div>
        </div>
      </div>
    </div>
  );
};
export default Step1;