import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  csvData: [], 
  step2SelectedData: [], 
  selectedRows: [], 
};
const csvDataSlice = createSlice({
    name: 'csvData',
    initialState,
    reducers: {
      setCsvData: (state, action) => {
        state.csvData = action.payload;
      },
      setStep2SelectedData: (state, action) => {
        state.step2SelectedData = action.payload;
      },
      setSelectedRows: (state, action) => {
        state.selectedRows = action.payload;
      },
      setStep3ExcludedCols: (state, action) => {
        state.step3ExcludedCols = action.payload; // Stores array of selected headers with their positions
      },
    },
  });
  

export const { setCsvData, setStep2SelectedData, setSelectedRows, setStep3ExcludedCols } = csvDataSlice.actions;

export default csvDataSlice.reducer;
