import { axiosProtected } from "./axios-interceptor";
import axios from 'axios';

export const sendRequest = async (
  method,
  path,
  requestData,
  contentType = "application/json",
  params = {}
) => {
  try {
    const BASE_URL = process.env.REACT_APP_IP;

    const config = {
      method: method,
      headers: {
        "Content-Type": contentType,
      },
      data: requestData,
      params: requestData?.params || params,
    };
    const response = await axiosProtected(`${BASE_URL}${path}`, config);
    if (response?.data) {
      return response.data;
    } else {
      return { error: response?.message || "Unknown error occurred" };
    }
  } catch (error) {
    throw error.response?.data?.message || error.message;
  }
};

export const sendRequest2 = async (method, url, data = null, params = {}) => {
  try {
    const token = JSON.parse(localStorage.getItem("token"))?.accessToken;

    const headers = {};
    if (token) headers.Authorization = `Bearer ${token}`;

    
    if (data && !(data instanceof FormData)) {
      headers['Content-Type'] = 'application/json';
    }

    const response = await axios({
      method,
      url: `${process.env.REACT_APP_IP}${url}`,
      data,
      params,
      headers,
    });

    return response.data;
  } catch (error) {
    console.error("Request Error:", error.response?.data || error.message);
    throw error.response ? error.response.data : new Error('An unexpected error occurred');
  }
};



