import { ChevronDownIcon } from '@heroicons/react/24/outline'
import dayjs from 'dayjs'
import React, { useMemo, useState } from 'react'
import ReactPaginate from 'react-paginate'
import { Link } from 'react-router-dom'
import { PAGE_LIMIT } from '../../utils/constants'
import { Table } from '../../utils/shared/table/table'
import { Loader } from '../Loader/Loader'

const SearchTable = ({ data, isLoading, handleSearchParams }) => {
  const [pageNumber, setPageNumber] = useState(0);

  const handlePageClick = (event) => {
    const current = event.selected;
    setPageNumber(event.selected);
    handleSearchParams('page', current)
  }

  const pageCount = useMemo(() => {
    let count = data && data.jobCount / PAGE_LIMIT;
    return count < 1 ? Math.ceil(0) : Math.ceil(count);
  }, [data]);

  const getJobsHeader = () => {
    return [
      { name: "Address", ref: "address" },
      { name: "Chemicals", ref: "chemicals" },
      { name: "Status", ref: "status" },
      { name: "Technician", ref: "technician" },
      { name: "Date", ref: "date" },
    ];
  };

  const jobsData = () => {

    return data?.jobs?.map((job) => ({
      address: (
        <div className="flex items-center">
          <Link
            className="hover:text-blue-500"
            to={`/job-details/${job?._id}`}
          >
            <p className="text-sm">{job?.address?.address}</p>
          </Link>
        </div>
      ),
      Chemicals: (
        <div>
          {job.chemicals.length > 1 ? (
            <div >
              <p className="text-sm">
                {job.chemicals[0].chemicalName}
                <button
                  className="ml-2  text-xs"
                  onClick={() => {
                    const dropdown = document.getElementById(
                      `dropdown-${job._id}`
                    );
                    dropdown.style.display =
                      dropdown.style.display === "none" ? "block" : "none";
                  }}
                >
                  <ChevronDownIcon
                    className="w-5 h-5 cursor-pointer pt-2"
                  />
                </button>
              </p>
              <div
                id={`dropdown-${job._id}`}
                style={{ display: "none" }}
                className=""
              >
                {job.chemicals.slice(1).map((chemical) => (
                  <p key={chemical._id} className="text-sm">
                    {chemical.chemicalName}
                  </p>
                ))}
              </div>
            </div>
          ) : (
            <p className="text-sm">{job.chemicals[0]?.chemicalName}</p>
          )}
        </div>
      ),
      status: (
        <span
          className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                  ${job.active === true
              ? "bg-blue-100 text-blue-800"
              : "bg-green-100 text-green-800"
            }`}
        >
          {job.active === true ? "In-Progress" : "Completed"}
        </span>
      ),
      technician:
        (job?.user ? job?.user[0]?.username : job?.technician[0]?.username) ??
        job?.user?.username,
      date: dayjs(job.startTime).format("DD MMM YYYY"),
      actions: <></>,
    }));
  };

  const tabledata = useMemo(() => ({
    tabledata: jobsData(),
    headers: getJobsHeader(),
    name: 'jobs'
  }), [data]);

  const DisplayLoader = () => {
    if (!isLoading) return null;
    return <Loader />;
  };

  return (
    <div>
      <DisplayLoader />
      <Table {...tabledata} />
      <div className="mt-3 float-right">
        <ReactPaginate
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={3}
          marginPagesDisplayed={2}
          pageCount={pageCount}
          previousLabel="<"
          pageClassName="page-item"
          pageLinkClassName="page-link"
          previousClassName="page-item"
          previousLinkClassName="page-link"
          nextClassName="page-item"
          nextLinkClassName="page-link"
          breakLabel="..."
          breakClassName="page-item"
          breakLinkClassName="page-link"
          containerClassName="pagination"
          activeClassName="active"
          renderOnZeroPageCount={null}
          forcePage={pageNumber}
        />
      </div>
    </div>
  )
}

export default SearchTable
