import React from 'react';
import { CheckIcon } from '@heroicons/react/20/solid';

// Utility function to convert degrees to compass direction
const degreesToCompass = (degrees) => {
    const compassDirections = [
        "N", "NNE", "NE", "ENE", "E", "ESE", "SE", "SSE",
        "S", "SSW", "SW", "WSW", "W", "WNW", "NW", "NNW", "N"
    ];
    const index = Math.round(degrees / 22.5) % 16;
    return compassDirections[index];
};

export const WeatherData = ({ weather, layout = "default" }) => {
    if (!weather) return <p className="text-gray-500">No weather data available.</p>;

    // Convert values
    const temperatureF = (weather.temperature * 9 / 5 + 32).toFixed(1); // °F
    const windSpeedMph = (weather.windSpeed / 0.44704).toFixed(1); // mph
    const windGustMph = (weather.windGust / 0.44704).toFixed(1); // mph
    const windDirectionCompass = degreesToCompass(weather.windDirection);

    const WeatherDataList = () => (
        <ul className="mt-6 space-y-4">
            <li className="flex space-x-3">
            {layout === "card" ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" /> : "" }
                <span className="text-sm text-gray-500">Wind Direction: {windDirectionCompass}</span>
            </li>
            <li className="flex space-x-3">
            {layout === "card" ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" /> : "" }
                <span className="text-sm text-gray-500">Wind Speed: {windSpeedMph} mph </span>
            </li>
            <li className="flex space-x-3">
            {layout === "card" ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" /> : "" }
                <span className="text-sm text-gray-500">Wind Gusts: {windGustMph} mph </span>
            </li>
            <li className="flex space-x-3">
                {layout === "card" ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" /> : "" }
                <span className="text-sm text-gray-500">Temperature: {temperatureF}°F</span>
            </li>
            <li className="flex space-x-3">
                {layout === "card" ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" /> : "" }
                <span className="text-sm text-gray-500">Cloud Cover: {weather.cloudCover}%</span>
            </li>
            <li className="flex space-x-3">
                {layout === "card" ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" /> : "" }
                <span className="text-sm text-gray-500">Rain Intensity: {weather.rainIntensity} mm/h</span>
            </li>
            <li className="flex space-x-3">
                {layout === "card" ? <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" /> : "" }
                <span className="text-sm text-gray-500">Precipitation Probability: {weather.precipitationProbability}%</span>
            </li>
        </ul>
    );

    if (layout === "card") {
        return (
            <section className="my-10">
                <p className="font-medium text-2xl">Weather Info:</p>
                <div className="mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
                    <div className="flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-6">
                        <WeatherDataList />
                    </div>
                </div>
            </section>
        );
    }
    return (
        <div>
            <h3 className="font-medium text-gray-900">Weather Details</h3>
            <WeatherDataList />
        </div>
    );
};
