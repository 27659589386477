import React from "react";

const Stepper = ({ currentStep }) => {
  const steps = ["Upload File", "Select Header", "Match Column", "Validate"];

  const getStepStyle = (step) => {
    if (step < currentStep) return "bg-black text-white";
    if (step === currentStep) return "bg-blue-500 text-white";
    return "bg-white text-gray-500 border border-gray-300";
  };

  return (
    <div className="flex items-center gap-4 p-2 rounded-md my-6 bg-blue-50">
      {steps.map((step, index) => (
        <React.Fragment key={index}>
          <div className="flex flex-col md:flex-row items-center space-x-2">
            <div
              className={`flex items-center justify-center h-7 w-7 md:w-10 md:h-10 rounded-full font-bold ${getStepStyle(
                index + 1
              )}`}
            >
              {index + 1}
            </div>
            <span className="text-sm font-medium text-gray-700">
              {step}
            </span>
          </div>
        </React.Fragment>
      ))}
    </div>
  );
};

export default Stepper;
