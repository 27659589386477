import axios from "axios";
import { jwtDecode } from "jwt-decode";
import { AuthApi } from '../lib/auth.api'

export const axiosProtected = axios.create();

axiosProtected.interceptors.request.use(
  async (config) => {
    let currentDate = new Date();
    const tokenObject = JSON.parse(localStorage.getItem("token"));
    if (!tokenObject || !tokenObject.accessToken) {
      return null;
    }
    const decodedToken = jwtDecode(tokenObject.accessToken);
    // Check if the token is expired
    if (decodedToken.exp * 1000 < currentDate.getTime()) {
      // Rotate the token if expired
      const data = await AuthApi.rotateToken(tokenObject.refreshToken);
      config.headers["Authorization"] = `Bearer ${data.tokens?.accessToken}`;
      localStorage.setItem("token", JSON.stringify(data.tokens));
    } else {
      config.headers["Authorization"] = `Bearer ${tokenObject.accessToken}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
