import { ArrowLeftIcon, CheckCircleIcon, CheckIcon, ClockIcon, CogIcon } from "@heroicons/react/24/outline";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { AddNotes } from "../../components/job-details/add-notes";
import { Loader } from "../../components/Loader/Loader";
import { useApiOperation } from "../../hooks/useApiOperation";
import { JobsApi } from "../../lib/jobs.api";
import { formatDate } from "../../utils/formateDate";
import { WeatherData } from "../../utils/shared/job-details/WeatherData";

const JobDetails = () => {
    const { id } = useParams();
    const navigate = useNavigate();
    const [job, setJob] = useState({})
    const [shouldRefetch, setShouldRefetch] = useState(true)
    const { startApiOperation, terminateApiOperation, apiLoading, apiError } = useApiOperation()

    useEffect(() => {
        if (!shouldRefetch) return;
        fetchJobById()
    }, [shouldRefetch])

    const fetchJobById = async () => {
        try {
            startApiOperation()
            const data = await JobsApi.getJobById({ jobId: id });
            setJob(data);
            terminateApiOperation()
            setShouldRefetch(false);
        } catch (error) {
            terminateApiOperation(['An error occurred while fetching jobs on property. Please try again later.'])
            setShouldRefetch(false);
        }
    };

    const DisplayLoader = () => {
        if (!apiLoading) return
        return <Loader />
    }

    const cards = [
        {
            title: "Start Time",
            icon: ClockIcon,
            time: formatDate(job.startTime)
        },
        {
            title: "Time Spent",
            icon: CogIcon,
            time: job.active === true
                ? <span className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-blue-100 text-blue-800">In-Progress</span>
                : (job.jobDuration === '  ' ? "less than a minute" : job.jobDuration)
        },
        {
            title: "Stop Time",
            icon: CheckCircleIcon,
            time: job.active === true
                ? <span className="inline-flex rounded-full px-2 text-xs font-semibold leading-5 bg-blue-100 text-blue-800">In-Progress</span>
                : formatDate(job.endTime)
        },
    ]

    const DisplayNotFound = () => {
        if (apiError || !job) return <div className="flex flex-col items-center justify-center gap-2 mt-20 text-center">
            <h1 className="text-3xl  ">No job found to display details</h1>
            <h1>Please refresh the page or try another address</h1>
        </div>
    }

    const JobInfoSection = () => {
        return (
            <section className="my-10">
                <div className="flex justify-start items-start md:items-center gap-2">
                    <span className="font-bold">Address: </span>
                    <span>{job?.address?.address}</span>
                </div>
                <div className="flex justify-start items-center">
                    <span className="font-bold">Status: </span>
                    <div className="whitespace-nowrap px-3 py-3">
                        <span className={`inline-flex rounded-full px-2 text-xs font-semibold leading-5 
                            ${job.active === true ? 'bg-blue-100 text-blue-800' : 'bg-green-100 text-green-800'}`}
                        >
                            {job.active === true ? 'In-Progress' : 'Completed'}
                        </span>
                    </div>
                </div>
            </section>
        )
    }

    const TotalSection = () => {
        if (!job?.meta) return null;
        const { meta } = job;

        return (
            <section className=" my-10">
                <p className="font-medium text-2xl">Total Summary: </p>

                <div className="mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
                    <div className="flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-2">
                        <div className="p-6 flex flex-col gap-3">
                            <div className='flex flex-col gap-2'>
                                <h1 className='text-xl'>Total</h1>
                                <div className='text-sm flex items-center gap-1'>
                                    <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" />
                                    <span className='font-medium'>Amount:</span> {meta?.totalSprayUsed.toFixed(2)} gal
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    const BackButton = () => {
        return (
            <button
                className="hidden md:flex absolute -top-1 left-0 items-center justify-center gap-2 py-2 px-3 border w-fit rounded-lg hover:bg-blue-50 transition-all"
                onClick={() => navigate(-1)} // Navigates to the previous page
            >
                <ArrowLeftIcon className="w-4 h-4" />
                Go Back
            </button>
        )
    }

    const TimelineSection = () => {

        const Cards = () => {
            if (!cards.length) return null;

            return cards.map((card) => (
                <div key={card.title} className="overflow-hidden rounded-lg bg-white shadow-sm border border-gray-300">
                    <div className="p-5">
                        <div className="flex items-center">
                            <div className="">
                                <dl>
                                    <div className='flex items-center gap-x-1'>
                                        <div>
                                            <card.icon className="h-6 w-6 text-blue-600" aria-hidden="true" />
                                        </div>
                                        <dt className="truncate text-sm font-medium text-blue-600">{card.title}</dt>
                                    </div>
                                    <dd>
                                        <p className="text-lg font-medium text-gray-900 mt-1 ml-1">{card.time}</p>
                                    </dd>
                                </dl>
                            </div>
                        </div>
                    </div>
                </div>
            ))
        }

        return (
            <div className="mt-2 grid grid-cols-1 gap-5 sm:grid-cols-2 lg:grid-cols-3">
                <Cards />
            </div>
        )
    }

    const ChemicalsUsed = () => {

        const ChemicalsList = () => {
            if (!job?.chemicals?.length) return null;
            return job.chemicals?.map(chemical => (
                <div key={chemical._id} className="flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-6">
                    <div className="p-6 ">
                        <h3 className="text-xl">{chemical.chemicalName}</h3>
                        <ul className="mt-6 space-y-4">
                            <li className="flex space-x-3">
                                <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" />
                                <span className="text-sm text-gray-500">Amount Used : {job?.meta?.chemicalsData?.find(data => data.chemicalName === chemical.chemicalName)?.amountUsed?.toFixed(2)}</span>
                            </li>
                            <li className="flex space-x-3">
                                <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" />
                                <span className="text-sm text-gray-500">Concentration : {chemical.concentration} {chemical.measurement}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            ))
        }

        return (
            <section className=" my-10">
                <p className="font-medium text-2xl">Chemicals used: </p>
                <div className="mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
                    <ChemicalsList />
                </div>
            </section>
        )
    }

    const HardwareUsed = () => {
        const HardwaresList = () => {
            if (!job?.hardwares?.length) return null;

            return job?.hardwares?.map(singleHardware => (
                <div key={singleHardware._id} className="flex overflow-hidden rounded-lg bg-white shadow-sm border-gray-300 border p-6">
                    <div className="p-6 ">
                        <h3 className="text-xl">{singleHardware.name}</h3>
                        <ul className="mt-6 space-y-4">
                            <li className="flex space-x-3">
                                <CheckIcon className="h-5 w-5 flex-shrink-0 text-blue-500" aria-hidden="true" />
                                <span className="text-sm text-gray-500">Hardware ID : {singleHardware.hwid}</span>
                            </li>
                        </ul>
                    </div>
                </div>
            ))
        }

        return (
            <section className=" my-10">
                <p className="font-medium text-2xl">Hardware used: </p>
                <div className="mt-6 space-y-4 gap-5 sm:mt-16 sm:grid sm:grid-cols-2 sm:gap-6 sm:space-y-0 lg:mx-auto lg:max-w-4xl xl:mx-0 xl:max-w-none xl:grid-cols-3">
                    <HardwaresList />
                </div>
            </section>
        )
    }

    return (        
            <div className="block m-5 md:relative">
                <DisplayLoader />
                <BackButton />
                <DisplayNotFound />
                <h1 className="text-3xl md:pb-10  font-semibold text-center">Job Details</h1>
                <JobInfoSection />
                <TimelineSection />
                <TotalSection />
                <AddNotes job={job} jobId={id} setShouldRefetch={setShouldRefetch} />
                <ChemicalsUsed />
                <HardwareUsed />
                <WeatherData weather={job?.weather?.data?.values} layout="card" />
                
            </div>
    )
}

export default JobDetails
