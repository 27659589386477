import { ChevronLeftIcon } from '@heroicons/react/24/outline'
import { useLoadScript } from '@react-google-maps/api'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { PrimaryButton } from '../../../components/Button/PrimaryButton'
import { AddEditForm } from '../../../components/clients/add-edit-form'
import { Input } from '../../../components/Input/Input'
import GoogleAutocompleteInput from '../../../components/Start/GoogleAutocompleteInput'
import { useApiOperation } from '../../../hooks/useApiOperation'
import { ClientsApi } from '../../../lib/clients.api'
import { NOTIFY_TYPE } from '../../../utils/constants'

const libraries = ["places"];

const initialState = {
  clientName: '',
  email: '',
  address: '',
  mapCenter: {
    lat: 0,
    lng: 0
  },
  squareFeet: ''
}

const DisplaySqFeet = ({ squareFeet, handleChange }) => {
  return <Input key='sq-feet-key' options={
    {
      label: 'Square Feet',
      name: 'sqft',
      type: "number",
      value: squareFeet,
      onChange: (e) => handleChange('squareFeet', e.target.value)
    }} />
}

const AddClient = () => {
  const navigate = useNavigate()
  const { startApiOperation, terminateApiOperation } = useApiOperation()
  const [clientDetails, setClientDetails] = useState(initialState)
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_API_KEY,
    libraries,
  });

  const handleClientDetailsChange = (name, value) => {
    setClientDetails((prevJobDetails) => ({
      ...prevJobDetails,
      [name]: value,
    }));
  };

  const handleSaveClient = async () => {
    try {
      startApiOperation()
      const clientPayload = {
        clientName: clientDetails.clientName,
        email: clientDetails.email,
        property: {
          address: clientDetails.address,
          mapCenter: clientDetails.mapCenter,
          squareFeet: clientDetails.squareFeet
        }
      }
      await ClientsApi.addNewClient(clientPayload)
      terminateApiOperation(['New Client Created Successfully'], NOTIFY_TYPE.Success)
      navigate('/clients')
      terminateApiOperation()
    } catch (error) {
      terminateApiOperation([error])
    }
  }

  const AddressBar = () => {
    if (!isLoaded) return
    return (
      <div className='flex py-2 w-full flex-col items-start gap-2'>
        <label className='text-sm'>Address</label>
        <GoogleAutocompleteInput setJobDetails={handleClientDetailsChange} address={clientDetails.address} label={false} />
      </div>
    )
  }

  return (
      <div className='flex flex-wrap flex-col p-10  w-full xl:w-1/2 gap-2'>
        <p className='flex sm:hidden font-medium text-2xl text-gray-500'>Create new client</p>
        <div className="w-full flex justify-between items-end ">
          <div>
            <PrimaryButton
              onClickEvent={() => navigate('/clients')}
              label={'Back'}
              icon={<ChevronLeftIcon className='w-4 h-4' />}
              classNames='gap-x-1 items-start ' />
          </div>
          <p className='hidden sm:flex font-medium text-2xl text-gray-500'>Create new client</p>
          <button
            type="button"
            disabled={false}
            className=" rounded-md border border-transparent px-8 py-2 bg-blue-500 text-sm font-medium text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500"
            onClick={handleSaveClient}
          >
            Save
          </button>
        </div>
        <AddEditForm clientDetails={clientDetails} handleChange={handleClientDetailsChange} />
        <AddressBar />
        <DisplaySqFeet handleChange={handleClientDetailsChange} squareFeet={clientDetails.squareFeet} />
      </div>
  )
}

export default AddClient