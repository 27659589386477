import React from "react";
import Map from "../../utils/shared/map/map";

const MapLayout = ({ children, propertyId, setPropertyId, jobsData }) => {
  return (
      <div className="w-full block p-5">
        <div className="flex md:flex-row flex-col gap-x-4">
          <Map
            data={jobsData}
            propertyId={propertyId}
            setPropertyId={setPropertyId}
          />
          {children}
        </div>
      </div>
  );
};

export default MapLayout;
