import { format } from "date-fns";
import { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { JsonEditor } from "../../components/jsonEditor/JsonEditor";
import { Loader } from "../../components/Loader/Loader";
import { ErrorAlert } from "../../components/Popups/ErrorAlert";
import { PopupBox } from "../../components/Popups/PopupBox";
import { useApiOperation } from "../../hooks/useApiOperation";
import { DataApi } from "../../lib/data.api";
import { HardwaresApi } from "../../lib/hardwares.api";
import { dateFormat } from "../../utils/constants";
import { getEndOfDay, getStartOfDay } from "../../utils/helpers";
import AdminMapLayout from "./layout";

const initialDateRange = {
  startDate: format(getStartOfDay(new Date()), dateFormat),
  endDate: format(getEndOfDay(new Date()), dateFormat),
};

const defaultParams = {
  hardwareId: "",
  startDate: initialDateRange.startDate,
  endDate: initialDateRange.endDate,
};

const AdminMapView = () => {
  const [searchParams] = useSearchParams(defaultParams);
  const { startApiOperation, terminateApiOperation, apiError, apiLoading } =
    useApiOperation();
  const [data, setData] = useState([]);
  const [hardwares, setHardwares] = useState([]);
  const [propertyId, setPropertyId] = useState(null);
  const [detailsModal, setDetailsModal] = useState(false);
  useEffect(() => {
    fetchHardwares();
  }, []);

  useEffect(() => {
    fetchData();
  }, [searchParams]);

  useEffect(() => {
    if (propertyId) {
      setDetailsModal(true);
    }
  }, [propertyId]);

  const fetchHardwares = async () => {
    try {
      startApiOperation();
      const responseData = await HardwaresApi.fetchHardwares();
      setHardwares(responseData);
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation([error]);
    }
  };

  const fetchData = async () => {
    try {
      startApiOperation();
      const selectedHardwareId = searchParams.get("hardwareId");
      const hardwareId = hardwares.find(
        (h) => h.hwid === selectedHardwareId
      )?.hwid;
      const requestParams = {
        hardwareId: hardwareId,
        startDate: searchParams.get("startDate"),
        endDate: searchParams.get("endDate"),
      };
      const data = await DataApi.fetchData(requestParams);
      setData(data);
      if (propertyId) {
        setPropertyId(null);
      }
      terminateApiOperation();
    } catch (error) {
      terminateApiOperation([error]);
    }
  };

  const RenderModal = () => {
    if (!propertyId) return null;
    return (
      <PopupBox
        modal={detailsModal}
        setModal={setDetailsModal}
        handleClose={() => {
          setDetailsModal(false);
        }}
        title="All Data Points"
      >
        <div className="max-h-[40rem] my-4 overflow-y-scroll">
          <JsonEditor propertyId={propertyId} />
        </div>
      </PopupBox>
    );
  };
  const DataLoader = () => {
    if (apiLoading && !data) return <Loader />;
    return null;
  };

  if (apiError) return <ErrorAlert errorMessages={apiError} />;

  return (
    <AdminMapLayout
      jobsData={data}
      propertyId={propertyId}
      setPropertyId={setPropertyId}
      setDetailsModal={setDetailsModal}
    >
      <DataLoader />
      <RenderModal />
    </AdminMapLayout>
  );
};

export default AdminMapView;
