import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddHardware } from '../../lib/addHardware.api';

const AcceptHardwareInvitation = () => {
    const navigate = useNavigate();
    const { hwid } = useParams();
    const [isVerified, setIsVerified] = useState(false);

    useEffect(() => {
        if (hwid) {
            AddHardware.verifyEmail(hwid)
                .then(() => {
                    setIsVerified(true);
                })
                .catch(() => {
                    setIsVerified(false);
                });
        }
        if (isVerified) { 
            navigate('/settings', { state: { activeTab: 'HARDWARE', openModal: true, hwid } });
        }
    }, [hwid, isVerified, navigate]);

    const GetVerificationStatus = () => {
        if (!isVerified) { 
            return (
                <p className="mb-0 text-red-500">
                    <strong>Invitation could not be accepted. Something went wrong.</strong>
                </p>
            );
        }
        return null; 
    };

    return (
        <div className="mt-28 flex justify-center">
            <div className="flex flex-col items-center">
                <GetVerificationStatus />
            </div>
        </div>
    );
};

export default AcceptHardwareInvitation;